import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess, isInternalUser} from '../../guards';

export default [
  {
    path: '/logo-template-builder/',
    name: 'logo-template-builder',
    meta: {
      title: 'Logo Template Builder',
      access: [...hasDashboardAccess, isInternalUser],
      layout: 'tool',
    },
    component: () =>
      import('./LogoTemplateBuilder.vue').catch(handleFailedChunk),
  },
];
