import {RouteRecordRaw} from 'vue-router';
import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDirectPaymentProcessor} from '@teemill/common/guards';
import {hasIntegration, hasDashboardPermissions} from '../../guards';

export default {
  name: 'delivery',
  path: '/delivery/',
  meta: {
    title: 'Delivery',
    integration: 'delivery',
    access: [
      hasIntegration,
      hasDashboardPermissions,
      hasDirectPaymentProcessor,
    ],
    dashboardPermissions: ['manage-settings'],
    layout: 'standard',
    breadcrumb: {
      type: 'page',
      parent: 'website',
    },
    search: {
      icon: 'envelope',
    },
  },
  component: () => import('./DeliveryMethodsPage.vue').catch(handleFailedChunk),
} as RouteRecordRaw;
