import {handleFailedChunk} from '@teemill/common/helpers';
import {
  hasIntegration,
  hasDashboardPermissions,
} from '@teemill/dashboard/src/guards';

export default [
  {
    path: 'orders',
    name: 'ecom.orders',
    meta: {
      title: 'Orders',
      integration: ['oms', 'orders'],
      dashboardPermissions: ['view-royalties'],
      access: [hasIntegration, hasDashboardPermissions],
      breadcrumb: {
        parent: 'ecom',
        position: 'custom',
      },
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: () => import('./OrdersPage.vue').catch(handleFailedChunk),
  },
  {
    path: 'orders/:id/',
    name: 'ecom.orders.view',
    meta: {
      title: 'View Order',
      integration: ['oms', 'orders'],
      dashboardPermissions: ['view-royalties'],
      access: [hasIntegration, hasDashboardPermissions],
      layout: 'standard',
      requireProject: true,
      breadcrumb: {parent: 'ecom.orders'},
    },
    component: () => import('./OrderView.vue').catch(handleFailedChunk),
  },
];
