import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/teemill-plans/',
    name: 'teemill-plans',
    meta: {
      title: 'Teemill Plans',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      access: hasDashboardAccess,
      layout: 'standard',
      search: {
        icon: 'shirt',
        url: () => '/teemill-plans/',
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "TeemillPlansPage" */ './TeemillPlansPage.vue'
      ).catch(handleFailedChunk),
  },
];
