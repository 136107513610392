export {default as TeerankDashboard} from './components/TeerankDashboard.vue';

export const TeerankSnapshot = () =>
  import('./components/pages/TeerankSnapshot.vue');
export const TeerankCampaigns = () =>
  import('./components/pages/TeerankCampaigns.vue');
export const TeerankPages = () => import('./components/pages/TeerankPages.vue');
export const TeerankSearches = () =>
  import('./components/pages/TeerankSearches.vue');
export const TeerankSuggestions = () =>
  import('./components/pages/TeerankSuggestions.vue');
export const TeerankBacklinks = () =>
  import('./components/pages/TeerankBacklinks.vue');
export const TeerankSupergrid = () =>
  import('./components/pages/TeerankSupergrid.vue');

export const CreateKeyPhraseCampaign = () =>
  import('./components/CreateKeyPhraseCampaign.vue');
export const KeyPhraseCampaignSelect = () =>
  import('./components/KeyPhraseCampaignSelect.vue');

export * from './types';
