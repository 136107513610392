import TmlApiClient from './tmlApiClient';
import {Product} from './types/product';
import {Variant} from './types/variant';

class WarehouseProductsApi extends TmlApiClient<
  Product,
  Partial<Product>,
  Partial<Product>
> {
  constructor() {
    super('/warehouse/catalog/products');
  }
}

class WarehouseVariantsApi extends TmlApiClient<
  Variant,
  Partial<Variant>,
  Partial<Variant>
> {
  constructor() {
    super('/warehouse/catalog/variants');
  }
}

export const warehouseCatalogApi = {
  products: new WarehouseProductsApi(),
  variants: new WarehouseVariantsApi(),
};
