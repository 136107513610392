import {isLoggedOut} from '../../guards';

export default [
  {
    path: '/login/',
    name: 'login',
    meta: {
      title: 'Login',
      access: [isLoggedOut],
      layout: 'blank',
      showOnSiteMap: true,
      allowPwaReload: true,
    },
    component: () => import('./Login.vue'),
  },
];
