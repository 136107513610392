import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/posta/',
    name: 'posta',
    meta: {
      title: 'Posta',
      access: hasDashboardAccess,
      layout: 'tool',
    },
    component: () => import('./PostaPage.vue').catch(handleFailedChunk),
  },
];
