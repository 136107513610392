import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/sales-activity/',
    name: 'sales-activity',
    meta: {
      title: 'Sales Activity',
      dashboardPermissions: ['view-royalties'],
      access: [hasDashboardPermissions],
      layout: 'standard',
    },
    component: () =>
      import(
        /* webpackChunkName: "PageSalesActivity" */ './SalesActivityPage.vue'
      ).catch(handleFailedChunk),
  },
];
