import {App, defineAsyncComponent} from 'vue';

export default {
  install(app: App) {
    app.component(
      'TmlContentWallet',
      defineAsyncComponent(() => import('./ContentWallet.vue'))
    );
  },
};
