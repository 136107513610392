import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/custom-product/',
    name: 'sample-shop-studio',
    meta: {
      title: 'Create custom product',
      access: hasDashboardAccess,
      layout: 'tool',
      search: {
        url: () => '/custom-product/',
      },
    },
    component: () => import('./SampleShopStudio.vue').catch(handleFailedChunk),
  },
];
