import {PolicyPage, PolicyViewerPage} from '@teemill/modules/policy';

export default [
  {
    path: '/policy/:url',
    name: 'policy',
    meta: {
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: PolicyPage,
  },
  {
    path: '/terms-of-use/',
    name: 'terms-of-use',
    meta: {
      title: 'Terms of Use',
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: PolicyViewerPage,
    props: {
      urlName: 'terms-of-use',
    },
  },
  {
    path: '/terms-of-sale/',
    name: 'terms-of-sale',
    meta: {
      title: 'Terms of Sale',
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: PolicyViewerPage,
    props: {
      urlName: 'terms-of-sale',
    },
  },
  {
    path: '/use-of-cookies-and-disclaimer/',
    name: 'cookie-policy',
    meta: {
      title: 'Use of Cookies & Disclaimer',
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: PolicyViewerPage,
    props: {
      urlName: 'cookie-policy',
    },
  },
  {
    path: '/privacy-policy/',
    name: 'privacy-policy',
    meta: {
      title: 'Privacy Policy',
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: PolicyViewerPage,
    props: {
      urlName: 'privacy-policy',
    },
  },
];
