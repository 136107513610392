import {
  TeerankSnapshot,
  TeerankCampaigns,
  TeerankPages,
  TeerankSearches,
  TeerankSuggestions,
  TeerankBacklinks,
  TeerankSupergrid,
} from '@teemill/modules/teerank';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/teerank/',
    redirect: '/teerank/snapshot/',
    name: 'teerank',
    meta: {
      integration: 'website',
      access: [hasIntegration],
      search: {
        icon: 'rocket',
      },
    },
    component: () => import('./TeerankPage.vue'),
    children: [
      {
        path: 'snapshot/',
        name: 'teerank.snapshot',
        meta: {
          title: 'Teerank',
          breadcrumb: {
            type: 'page',
            parent: 'dashboard',
          },
          search: {
            icon: 'rocket',
          },
        },
        component: TeerankSnapshot,
      },
      {
        path: 'campaigns/',
        name: 'teerank.campaigns',
        meta: {
          title: 'Teerank campaigns',
          breadcrumb: {
            type: 'page',
            parent: 'teerank.snapshot',
          },
          search: {
            icon: 'rocket',
          },
        },
        component: TeerankCampaigns,
      },
      {
        path: 'pages/',
        name: 'teerank.pages',
        meta: {
          title: 'Teerank pages',
          breadcrumb: {
            type: 'page',
            parent: 'teerank.snapshot',
          },
          search: {
            icon: 'rocket',
          },
        },
        component: TeerankPages,
      },
      {
        path: 'searches/',
        name: 'teerank.searches',
        meta: {
          title: 'Teerank searches',
          breadcrumb: {
            type: 'page',
            parent: 'teerank.snapshot',
          },
          search: {
            icon: 'rocket',
          },
        },
        component: TeerankSearches,
      },
      {
        path: 'ideas/',
        name: 'teerank.ideas',
        meta: {
          title: 'Teerank ideas',
          breadcrumb: {
            type: 'page',
            parent: 'teerank.snapshot',
          },
          search: {
            icon: 'rocket',
          },
        },
        component: TeerankSuggestions,
      },
      {
        path: 'backlinks/',
        name: 'teerank.backlinks',
        meta: {
          title: 'Teerank backlinks',
          breadcrumb: {
            type: 'page',
            parent: 'teerank.snapshot',
          },
          search: {
            icon: 'rocket',
          },
        },
        component: TeerankBacklinks,
      },
      {
        path: 'supergrid/',
        name: 'teerank.supergrid',
        meta: {
          title: 'Teerank supergrid',
          breadcrumb: {
            type: 'page',
            parent: 'teerank.snapshot',
          },
          search: {
            icon: 'rocket',
            show: false,
          },
        },
        component: TeerankSupergrid,
      },
    ],
  },
];
