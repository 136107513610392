import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/marketing/automation/content/',
    name: 'marketing.automation.content',
    meta: {
      title: 'Content',
      layout: 'standard',
      integration: 'website',
      access: [hasIntegration],
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      search: {
        show: false,
      },
    },
    component: () =>
      import('./automation/content/AutomationContentIndex.vue').catch(
        handleFailedChunk
      ),
    children: [
      {
        path: '',
        name: 'marketing.automation.content.list',
        meta: {
          title: 'Content',
          layout: 'standard',
          breadcrumb: {
            type: 'page',
            parent: 'dashboard',
          },
        },
        component: () =>
          import(
            '../../../../modules/src/automation-content/components/ContentGroupList.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'create/',
        name: 'marketing.automation.content.create',
        meta: {
          title: 'Content Group',
          layout: 'standard',
          breadcrumb: {
            type: 'page',
            parent: 'marketing.automation.content.list',
          },
        },
        component: () =>
          import(
            '../../../../modules/src/automation-content/components/ContentGroup.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: ':group/',
        name: 'marketing.automation.content.group',
        meta: {
          title: 'Content Group',
          layout: 'standard',
          breadcrumb: {
            type: 'page',
            parent: 'marketing.automation.content.list',
          },
        },
        component: () =>
          import(
            '../../../../modules/src/automation-content/components/ContentGroup.vue'
          ).catch(handleFailedChunk),
      },
    ],
  },
];
