import {LayoutSections} from '@teemill/modules/app';

export const standardNoSidebarConfig: LayoutSections = {
  announcement: false,
  header: {
    show: true,
    fixed: true,
    overlay: false,
    height: '4rem',
    width: '100%',
    logo: true,
    logoMaxWidth: '220px',
    centerLogo: false,
    content: true,
  },
  sidebar: {show: false, fixed: false, full: false, width: '220px'},
  content: {show: true, width: '100%'},
  footer: false,
  subFooter: false,
  navBar: false,
};
