import {handleFailedChunk} from '@teemill/common/helpers';
import {isLoggedIn} from '../../guards';

export default [
  {
    path: '/receipt/:orderHash',
    name: 'Receipt',
    meta: {
      title: 'Receipt',
      layout: 'standard',
      search: {
        show: false,
      },
      access: [isLoggedIn],
    },
    component: () =>
      import('./DashboardReceiptPage.vue').catch(handleFailedChunk),
  },
  {
    path: '/invoice/:orderHash',
    name: 'Invoice',
    meta: {
      title: 'Invoice',
      layout: 'standard',
      search: {
        show: false,
      },
      access: [isLoggedIn],
    },
    component: () =>
      import(
        /* webpackChunkName: "PageProformaInvoice" */ './ProformaInvoicePage.vue'
      ).catch(handleFailedChunk),
  },
  {
    path: '/update-address/:orderHash',
    name: 'Update Address',
    meta: {
      title: 'Update Address',
      layout: 'standard',
      search: {
        show: false,
      },
      access: [isLoggedIn],
    },
    component: () =>
      import('./DashboardUpdateAddressPage.vue').catch(handleFailedChunk),
  },
  {
    path: '/order-history/:customerHash?',
    name: 'Order History',
    meta: {
      title: 'Order History',
      layout: 'standard',
      access: [isLoggedIn],
    },
    component: () =>
      import('./DashboardHistoryPage.vue').catch(handleFailedChunk),
  },
];
