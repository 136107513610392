import {defineStore} from 'pinia';
import {Project} from '../models';
interface State {
  projects: Project[];
}

export const useProjectStore = defineStore('project', {
  state: (): State => ({
    projects: [],
  }),

  getters: {
    active(): Project | undefined {
      const projectCode = this.router.currentRoute.value.query.project;

      if (typeof projectCode !== 'string') {
        return undefined;
      }

      const project = this.find(projectCode);

      const domain = project?.platform?.domain;

      if (project?.isTurbo && domain !== undefined) {
        const url = `https://${domain}/dashboard?project=${project.id}`;

        const pageQueryParams = new URLSearchParams(window.location.search);

        if (pageQueryParams.get('operatorMode') !== 'headless') {
          console.log('Redirect', project.id, 'to', url);
          window.localStorage.removeItem('project');

          if (import.meta.env.PROD) {
            window.location.href = url;
            return undefined;
          }
        } else {
          console.log(`Headless mode. Aborting redirect to ${url}`);
        }
      }

      return project;
    },

    find() {
      return (project: string): Project | undefined =>
        this.projects.find((p: Project) => p.id === project);
    },
  },

  actions: {
    update(project: Project) {
      const existingProject = this.projects.find(p => p.id === project.id);

      existingProject
        ? Object.assign(existingProject, project)
        : this.projects.push(project);
    },
  },
});
