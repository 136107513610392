import {http} from '@teemill/common/services';
import {BillingCycle, Subscription, SubscriptionIntentResponse} from '../types';
import {
  SubscriptionNotFoundError,
  SubscriptionServerError,
  SubscriptionSetupFailedError,
  SubscriptionValidationError,
} from '../errors/subscriptionErrors';
import {subscriptionRoutes} from './subscriptionRoutes';

export class SubscriptionProvider {
  public static createSubscriptionIntent(params: {
    with_trial?: boolean;
    update: boolean;
    billing_cycle?: BillingCycle;
  }): Promise<SubscriptionIntentResponse> {
    return http
      .post(subscriptionRoutes.createSubscriptionIntent, {
        ...params,
      })
      .success((data: SubscriptionIntentResponse) => data)
      .throw(404, () => new SubscriptionNotFoundError())
      .throw(
        403,
        ({data}: {data: {message: string}}) =>
          new SubscriptionSetupFailedError(data?.message)
      )
      .throw(
        '5xx',
        () =>
          new SubscriptionServerError(
            'Server error encountered creating subscription intent'
          )
      )
      .throwValidation(
        (message: string) => new SubscriptionValidationError(message)
      )
      .output();
  }

  public static updateBillingCycle(
    subscription_id: number,
    billing_cycle: BillingCycle
  ): Promise<void> {
    return http
      .put(subscriptionRoutes.updateBillingCycle, {
        subscription_id,
        billing_cycle,
      })
      .throw(404, () => new SubscriptionNotFoundError())
      .throw(
        '5xx',
        () =>
          new SubscriptionServerError(
            'Server error encountered updating billing cycle'
          )
      )
      .throwValidation(
        (message: string) => new SubscriptionValidationError(message)
      )
      .output();
  }

  public static getSubscription(): Promise<Subscription> {
    return http
      .get(subscriptionRoutes.getSubscription)
      .success(
        ({
          subscription,
        }: {
          subscription: {
            id: number;
            plan: 'pro';
            billing_cycle: BillingCycle;
          };
        }): Subscription => {
          return {
            id: subscription.id,
            plan: subscription.plan,
            billingCycle: subscription.billing_cycle,
          };
        }
      )
      .throw(404, () => new SubscriptionNotFoundError())
      .throw(
        '5xx',
        () =>
          new SubscriptionServerError(
            'Server error encountered fetching subscription'
          )
      )
      .output();
  }

  public static canReceiveFreeTrial(): Promise<boolean> {
    return http
      .get(subscriptionRoutes.canReceiveFreeTrial)
      .success(
        ({can_receive_free_trial}: {can_receive_free_trial: boolean}) =>
          can_receive_free_trial
      )
      .output();
  }
}
