import {handleFailedChunk} from '@teemill/common/helpers';
import {isLoggedIn} from '../../guards';

export default [
  {
    path: '/project/create',
    name: 'project.create',
    meta: {
      title: 'Create project',
      access: [isLoggedIn],
      layout: 'blank',
      requireProject: false,
    },
    component: () => import('./Create.vue').catch(handleFailedChunk),
    beforeEnter: (to, from) => {
      if (!to.query.integration) {
        return from;
      }
    },
  },
  {
    path: '/project/switch',
    name: 'project.switch',
    meta: {
      title: 'Switching project',
      access: [isLoggedIn],
      layout: 'blank',
    },
    component: () => import('./Switch.vue').catch(handleFailedChunk),
  },
];
