export default {
  toggleDarkMode: state => {
    state.isDark = !state.isDark;
    window.localStorage.setItem('darkMode', state.isDark);
  },

  initialiseDarkMode: (state, payload) => {
    state.isDark = payload;
  },
};
