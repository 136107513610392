import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/reviews/',
    name: 'reviews',
    meta: {
      title: 'Reviews',
      breadcrumb: {
        type: 'page',
        parent: 'analytics',
      },
      integration: 'website',
      access: [hasIntegration],
      layout: 'standard',
    },
    component: () =>
      import(
        /* webpackChunkName: "PageStoreReviews" */ './StoreReviewsPage.vue'
      ).catch(handleFailedChunk),
  },
];
