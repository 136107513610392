import {handleFailedChunk} from '@teemill/common/helpers';
import {
  hasDashboardAccess,
  hasStore,
  hasDashboardPermissions,
} from '@teemill/common/guards';

import {Index, List} from '@teemill/modules/teemail';

export default {
  path: 'drafts',
  meta: {
    title: 'Drafts',
    access: [hasDashboardAccess, hasStore],
    layout: 'standard',
  },
  component: Index,
  children: [
    {
      name: 'teemail.drafts.list',
      path: '',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'teemail.index',
        },
        title: 'Drafts',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
      },
      component: List,
    },
    {
      path: 'create',
      name: 'teemail.drafts.create',
      meta: {
        title: 'Create Draft',
        access: [hasDashboardAccess, hasStore],
        layout: 'tool',
        cloudRedirect: () => {
          return {
            name: 'website.teemail.drafts.create',
          };
        },
      },
      component: () =>
        import('./TeemailDraftEditor.vue').catch(handleFailedChunk),
    },
    {
      path: 'edit/:page',
      name: 'teemail.drafts.edit',
      meta: {
        title: 'Edit Draft',
        access: [hasDashboardAccess, hasStore],
        layout: 'tool',
        cloudRedirect: to => {
          return {
            name: 'website.teemail.drafts.edit',
            params: {
              draft: to.params.page,
            },
          };
        },
      },
      component: () =>
        import('./TeemailDraftEditor.vue').catch(handleFailedChunk),
    },
  ],
};
