import {Module} from 'vuex';

const plugin: Module<{active: {name: string; code: string} | null}, any> = {
  namespaced: true,

  state: {
    active: null,
  },

  mutations: {
    setActive(state, plugin) {
      state.active = plugin;
    },

    clearActive(state) {
      state.active = null;
    },
  },
};

export default plugin;
