import {LiveChatCustomIdentityProvider} from '../types/liveChatCustomIdentityProvider';
import {LiveChatProvider} from './liveChatProvider';

export const liveChatCustomerIdentityProvider: LiveChatCustomIdentityProvider =
  () => {
    return () => {
      return {
        getToken: () => LiveChatProvider.getToken(),
        getFreshToken: () => LiveChatProvider.getToken(),
        hasToken: () => Promise.resolve(false),
        invalidate: () => Promise.resolve(),
      };
    };
  };
