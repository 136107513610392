import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardPermissions, isPro, hasIntegration} from '../../guards';

export default [
  {
    path: '/flows',
    name: 'Flows Index',
    meta: {
      integration: 'website',
      access: [isPro, hasIntegration],
      search: {
        show: false,
      },
    },
    component: () => import('./FlowsIndex.vue').catch(handleFailedChunk),
    children: [
      {
        path: '',
        name: 'Flows',
        meta: {
          title: 'Flows',
          breadcrumb: {
            type: 'page',
            parent: 'teemail.index',
          },
          layout: 'standard',
          cloudRedirect: to => ({
            name: 'website.teemail.flows.index',
          }),
        },
        component: () =>
          import(/* webpackChunkName: "FlowsPage" */ './FlowsPage.vue').catch(
            handleFailedChunk
          ),
      },
      {
        path: 'drafts',
        name: 'Flow Drafts Index',
        component: () =>
          import('./FlowDraftIndex.vue').catch(handleFailedChunk),
        children: [
          {
            path: 'create',
            name: 'Flows Create',
            meta: {
              title: 'Create Draft',
              dashboardPermissions: ['send-mail'],
              access: [hasDashboardPermissions],
              layout: 'tool',
              cloudRedirect: to => ({
                name: 'website.teemail.flows.create',
                query: {
                  flowType: to.query.flowType,
                  redirectUrl: to.query.redirectUrl,
                },
              }),
            },
            component: () =>
              import('./FlowDraftEditor.vue').catch(handleFailedChunk),
          },
          {
            path: 'edit/:page',
            name: 'Flows Edit',
            meta: {
              title: 'Edit Draft',
              dashboardPermissions: ['send-mail'],
              access: [hasDashboardPermissions],
              layout: 'tool',
              cloudRedirect: to => ({
                name: 'website.teemail.flows.edit',
                params: {
                  draft: to.params.page,
                },
                query: {
                  flow: to.query.flow,
                  redirectUrl: to.query.redirectUrl,
                },
              }),
            },
            component: () =>
              import('./FlowDraftEditor.vue').catch(handleFailedChunk),
          },
        ],
      },
    ],
  },
];
