import {formatUrl} from '../helpers';
import {Manufacturer} from '../models/manufacturer/manufacturer';
import CustomDomain from '../models/store/customDomain';

export class Store {
  constructor({
    id,
    pro,
    name,
    domain,
    customDomain,
    email,
    theme,
    favicon,
    password,
    logo,
    customers = [],
    manufacturer,
    projectId,
    approval,
    paymentProcessor = {
      type: 'teemill',
      accounts: {
        stripe: undefined,
      },
    },
    currency = 'GBP',
  } = {}) {
    this.id = id;
    this.pro = pro;
    this.name = name;
    this.domain = domain;
    this.customDomain = CustomDomain.map(customDomain);
    this.email = email;
    this.theme = theme;
    this.favicon = favicon;
    this.password = password;
    this.logo = logo;
    this.customers = customers;
    this.manufacturer = new Manufacturer(manufacturer);
    this.projectId = projectId;
    this.approval = approval;
    this.paymentProcessor = paymentProcessor;
    this.currency = currency;
  }

  static get active() {
    return $store.state?.store?.active;
  }

  /**
   * @returns {'direct' | 'teemill'}
   */
  static get paymentProcessorType() {
    return this.active?.paymentProcessor?.type || 'teemill';
  }

  static get(id) {
    return $axios
      .get(formatUrl(`/omnis/v3/division/${id}`))
      .success(s => new Store(s))
      .output();
  }

  static create(
    name,
    demographic,
    integrationType,
    promoUrl = null,
    affiliate = null
  ) {
    return $axios
      .post(formatUrl('/omnis/v3/shop/store/create/'), {
        name,
        demographic,
        integrationType,
        promoUrl,
        affiliate,
      })
      .success(data => {
        const store = new Store(data);

        $store.dispatch('store/update', store);

        $store.dispatch('store/active', store);
      }, false);
  }

  static update(store, properties) {
    return $axios
      .post(formatUrl('/omnis/v3/shop/store/update/'), {
        id: store.id,
        ...properties,
      })
      .success(data => {
        $store.dispatch('insertInitialState', data);
      }, false);
  }
}
