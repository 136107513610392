import Vue from 'vue';
import {formatUrl} from '@teemill/common/helpers';

export default {
  namespaced: true,

  state: {
    products: [],
    selectedProduct: null,
    waitingForProducts: true,
  },

  mutations: {
    setProductData(state, data) {
      if (data && !Array.isArray(data)) {
        throw new Error(`Expected Array, instead got ${JSON.stringify(data)}`);
      }

      state.products = data;
    },
    addProductData(state, data) {
      if (data && !Array.isArray(data)) {
        throw new Error(`Expected Array, instead got ${JSON.stringify(data)}`);
      }

      state.products = state.products.concat(data);
    },
    waitingForProducts(state, data) {
      state.waitingForProducts = data;
    },
    setSelectedProduct(state, data) {
      Vue.set(state, 'selectedProduct', data);
    },
    removeSelectedProduct(state) {
      state.selectedProduct = null;
    },
    toggleProductEnabled(state, data) {
      const productToToggle = state.products.find(
        product => product.id === data
      );

      productToToggle.enabled = !productToToggle.enabled;
      state.selectedProduct.enabled = !state.selectedProduct.enabled;
    },
    updateProductData(state, data) {
      const product = state.products.find(p => p.id === data.product_id);

      Object.keys(data).forEach(prop => {
        product[prop] = data[prop];
      });
    },
    deleteProduct(state, data) {
      state.products = state.products.filter(product => product.id !== data);
    },
    unlockProduct(state, data) {
      const productToUnlock = state.products.find(
        product => product.id === data
      );

      if (productToUnlock) {
        productToUnlock.locked = false;
      }
    },
  },

  getters: {
    productData: state => {
      if (state && state.products) {
        /**
         * Needed to reset product data array to prevent weirdness with enabling
         * & disabling products
         */
        const products = JSON.parse(JSON.stringify(state.products));

        return products.map(product => {
          const values = Object.values(product.options);

          if (typeof values[0] !== 'undefined' && values[0]) {
            if (typeof values[0].images !== 'undefined' && values[0].images) {
              const frontImages = values[0].images[0].front;
              product.firstFlatImage = frontImages;
            }
          }

          return product;
        });
      }
      return [];
    },

    getId(state, getters, rootState) {
      const products = Object.values(state.products);

      let routeProduct = rootState.route.params.product || '';
      let product = products.find(p => p.urlName === routeProduct);

      /**
       * ? If on the first pass of the stores products a match wasn't found then
       * ? try again without a manufacturer id (If there is one).
       */
      if (!product) {
        routeProduct = routeProduct.replace(/-[0-9]+(|\/)$/, '');

        /**
         * ! This will also match non manufacturer id's which could cause some
         * ! issues. This use case was deemed small enough to not be a concern.
         */
        product = products.find(p => p.urlName === routeProduct);
      }

      if (product) {
        return product.id;
      }

      return null;
    },

    get: (state, getters) => productId => {
      if (state.products) {
        if (productId) {
          return state.products.find(product => product.id === productId);
        }

        if (getters.getId) {
          return Object.assign(
            {},
            state.products.find(product => product.id === getters.getId)
          );
        }
      }
      return null;
    },

    getProductImages: (state, getters) => (size, type, productId) => {
      const product = getters.get(productId);

      if (!product) {
        return [];
      }

      let images = [];

      if (!type || type === 'lifestyle') {
        images = [
          ...images,
          ...getters.getProductLifeStyleImages(size, product.id),
        ];
      }

      if (!type || type === 'flat') {
        images = [...images, ...getters.getProductFlatImages(size, product.id)];
      }

      return images;
    },

    getProductLifeStyleImages: (state, getters) => (size, productId) => {
      const product = getters.get(productId);
      const foundImages = new Set();
      const images = [];

      const lifestyleImages = Object.values(product.lifestyleImages);
      for (let i = 0; i < lifestyleImages.length; i++) {
        const lifestyleImage = lifestyleImages[i][size];

        if (!foundImages.has(lifestyleImage)) {
          images.push({
            productId: product.id,
            url: lifestyleImage,
          });

          foundImages.add(lifestyleImage);
        }
      }

      return images;
    },

    getProductFlatImages: (state, getters) => (size, productId) => {
      const product = getters.get(productId);
      const images = [];

      const productOptionKeys = Object.keys(product.options);
      for (let i = 0; i < productOptionKeys.length; i++) {
        const productOptionKey = productOptionKeys[i];
        const productOption = product.options[productOptionKey];

        if (productOption.flatImage) {
          const flatImage = productOption.flatImage[size];

          images.push({
            productId: product.id,
            optionId: productOptionKey,
            url: flatImage,
          });
        }

        if (productOption.backFlatImage) {
          const backFlatImage = productOption.backFlatImage[size];

          images.push({
            productId: product.id,
            optionId: null,
            url: backFlatImage,
          });
        }
      }

      return images;
    },

    getProductDefaultImage: (state, getters) => (size, productId) => {
      const product = getters.get(productId);

      if (product.lifestyleAsDefault || !Object.keys(product.options).length) {
        const lifeStyleImages = getters.getProductLifeStyleImages(
          size,
          productId
        );

        if (lifeStyleImages.length) {
          return lifeStyleImages[0].url;
        }
      }

      const flatImages = getters.getProductFlatImages(size, productId);
      if (flatImages.length) {
        return flatImages[0].url;
      }

      return '';
    },
  },

  actions: {
    async toggleProductEnabled({state, commit}) {
      return new Promise(resolve => {
        window.axios
          .post(
            formatUrl('/omnis/v3/dashboard/product/toggle-product-enabled/'),
            {
              product: state.selectedProduct,
            }
          )
          .then(({data}) => {
            if (data) {
              if (Object.prototype.hasOwnProperty.call(data, 'id')) {
                if (
                  Object.prototype.hasOwnProperty.call(data, 'action') &&
                  data.action === 'delete'
                ) {
                  commit('deleteProduct', data.id);
                } else {
                  commit('toggleProductEnabled', data.id);
                }
              }
              resolve(data);
            } else {
              resolve({type: 'error'});
            }
          });
      });
    },
    async deleteProduct({state, commit}) {
      return new Promise(resolve => {
        window.axios
          .post(formatUrl('/omnis/v3/dashboard/product/delete-product/'), {
            product: state.selectedProduct,
          })
          .then(({data}) => {
            if (data) {
              if (Object.prototype.hasOwnProperty.call(data, 'id')) {
                commit('deleteProduct', data.id);
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  data,
                  'unlockedProductId'
                ) &&
                data.unlockedProductId
              ) {
                commit('unlockProduct', data.unlockedProductId);
              }
              resolve(data);
            } else {
              resolve({type: 'error'});
            }
          });
      });
    },
  },
};
