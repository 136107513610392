import {App, Plugin} from 'vue';
import {Store} from 'vuex';

interface PluginOptions {
  store: Store<any>;
}

let store: PluginOptions['store'] | undefined;

const hasPermission = (name: string) => {
  if (store === undefined) {
    throw new Error('Permissions plugin has not been correctly installed.');
  }

  return store.getters['auth/hasDashboardPermissions'](name);
};

const doesntHavePermission = (name: string) => !hasPermission(name);

export const usePermissions = () => ({
  has: hasPermission,
  doesntHave: doesntHavePermission,
});

export const permissions: Plugin = {
  async install(app: App, options: PluginOptions): Promise<void> {
    store = options.store;
  },
};
