import {App} from 'vue';
import {Router, RouteRecordRaw} from 'vue-router';

import BaseRoute from '../index';

export type PluginOptions = {
  router: Router;
  routes: RouteRecordRaw[];
};

export default {
  install(app: App, options: PluginOptions) {
    const {routes, router} = options;

    router.addRoute(BaseRoute);

    routes.map(route => {
      router.addRoute('teemail.base', route);
    });
  },
};
