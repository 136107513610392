import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/my-products/:urlName?',
    name: 'My Products',
    meta: {
      title: 'My Products',
      breadcrumb: {
        type: 'page',
        parent: 'products',
      },
      access: hasDashboardAccess,
      layout: 'standard',
      search: {
        icon: 'shirt',
        url: () => '/my-products/',
      },
      cloudRedirect: to => {
        return {
          name: 'catalog.products.list',
        };
      },
    },
    component: () =>
      import(/* webpackChunkName: "PageMyProducts" */ './MyProducts.vue').catch(
        handleFailedChunk
      ),
  },
];
