import {createStore} from 'vuex';
import {events} from '@teemill/common/services';

import {
  notifications,
  misc,
  currency,
  splitTest,
  overlayElements,
  navigationElements,
  darkMode,
} from '@teemill/common/vuex';

import cart from '../store/cart';
import auth from '../store/auth';
import storeModule from '../store/store';
import product from '../store/product';
import logo from '../store/logo';
import plugin from '../store/plugin';
import {Customer} from '@teemill/common/models';
import {Store} from '@teemill/common/classes';
import {useLiveChat} from '@teemill/modules/live-chat';
import {useProjectStore} from '../stores/project';
import {Project} from '../models';

export const store = createStore<{
  user?: Customer;
  store: any;
  auth: any;
  notifications: any;
  darkMode: any;
}>({
  modules: {
    notifications,
    misc,
    overlayElements,
    navigationElements,
    splitTest,
    currency,
    cart,
    store: storeModule,
    logo,
    product,
    auth,
    plugin,
    context: {state: {context: 'teemill'}},
    darkMode,
  },

  state: {
    user: undefined,
    store: null,
    auth: undefined,
    notifications: null,
    darkMode: null,
  },

  actions: {
    /**
     * @name Insert Initial State
     * @description Inserts initial app state on app load
     *
     * @param {Object} initialState initial app state
     */
    insertInitialState(
      {commit, dispatch},
      {user, stores, manufacturers: projects, divisionLogo, splitTest}
    ) {
      if (user) {
        commit('updateUser', new Customer(user));
      }

      const projectStore = useProjectStore();

      if (stores) {
        const mappedStores: Store[] = stores.map((s: any) => new Store(s)); // TODO Store typing

        dispatch('store/update', mappedStores);
      }

      if (projects) {
        projects.forEach((project: any) => {
          // TODO remove after core-api release
          project.logo = project.newLogo || project.logo;

          projectStore.update(new Project(project));
        });
      }

      if (divisionLogo) {
        commit('logo/update', divisionLogo);
      }

      if (splitTest) {
        commit('splitTest/install', splitTest);
      }

      return true;
    },

    wipeInitialState({commit}) {
      commit('updateUser', null);
      commit('store/reset');
      useProjectStore().$reset();
      useLiveChat().destroy();
    },
  },

  mutations: {
    /**
     * @name Update User
     * @description Updates the logged in user
     *
     * @param state
     * @param {Customer|User} user
     */
    updateUser(state, user) {
      if (user) {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.setAttribute('defer', '');
        script.addEventListener('load', () => {
          events.emit('stripe-loaded', true);
        });
        document.body.appendChild(script);
      }

      state.user = user;
    },
  },

  strict: import.meta.env.DEV,
});
