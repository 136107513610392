<template>
  <tml-new-popup
    name="feedback-popup"
    layout="standard"
    size="normal"
    height="auto"
    title="Improve Teemill"
    @close="closePopup"
  >
    <template #content>
      <p class="mb-4">
        If you've got feedback, however small, please share it below. We read
        every one.
      </p>
      <p class="mb-4">
        <b>Please submit one issue at a time. </b>You can use this form as many
        times as you want.
      </p>

      <tml-form ref="feedbackForm" class="w-full" form-name="feedbackForm">
        <tml-new-segmented-control
          v-model="type"
          name="type"
          label="Feedback type"
          pre-selected="issue"
          :options="feedbackTypes"
          :validation-rules="{required: true}"
        />
        <transition name="fade">
          <p
            v-show="!app.isAtlas && type.value === 'question'"
            class="mb-4 text-sm"
          >
            Need help? You might find a faster answer on the support section on
            each plugin, at the bottom of each blog, or in our
            <tml-anchor
              href="/store-help/"
              @click="$overlays.close('feedback-popup')"
            >
              FAQs
            </tml-anchor>.
          </p>
        </transition>
        <tml-textarea
          class="mb-4"
          field-name="feedbackText"
          :validation-rules="{required: true}"
          :placeholder="`${placeholderByType} in as much detail as you can for our team`"
          placeholder-animation="hide"
        />
        <transition name="fade">
          <tml-textarea
            v-show="type.value === 'issue'"
            class="mb-4"
            field-name="proposal"
            placeholder="What do you propose we change in response?"
            :validation-rules="{required: app.isAtlas}"
          />
        </transition>
      </tml-form>
      <tml-uploader
        ref="uploader"
        v-model="uploadedImages"
        accept="image/*"
        :multiple="false"
        :max-file-size="5000000"
        listen-for-global-paste-events
      >
        <template #default="{fileBag, triggerUploader}">
          <div :class="{'px-4': fileBag.length}">
            <tml-sortable-list
              :items="fileBag"
              :max="maxAttachments"
              :item-class-list="
                fileBag.length
                  ? 'flex-1 mr-1'
                  : maxDevice('md')
                    ? 'col-6'
                    : 'col-3'
              "
              allow-delete
            >
              <template #cell="props">
                <tml-image :src="props.item.src" :alt="props.item.name" />
              </template>
              <template #appendable>
                <tml-placeholder
                  v-if="uploadedImages.length < maxAttachments"
                  class="flex-1"
                  :icon="faImages"
                  text="Add screenshot"
                  :aspect-ratio="1"
                  @click="triggerUploader"
                />
              </template>
            </tml-sortable-list>
          </div>
        </template>
      </tml-uploader>
    </template>

    <template #footer>
      <tml-button
        text="Submit"
        :loading="submitInProgress"
        :disabled="submitInProgress || !formValid"
        primary
        fill
        @click="submitFeedback()"
      />
    </template>
  </tml-new-popup>
</template>

<script>
import {formatUrl, maxDevice} from '@teemill/common/helpers';
import {faImages} from '@fortawesome/pro-light-svg-icons';

import {
  TmlNewPopup,
  TmlForm,
  TmlNewSegmentedControl,
  TmlAnchor,
  TmlTextarea,
  TmlUploader,
  TmlSortableList,
  TmlImage,
  TmlPlaceholder,
  TmlButton,
} from '@teemill/components';

import mixin from './mixin';

export default {
  name: 'TmlFeedbackPopup',

  components: {
    TmlNewPopup,
    TmlForm,
    TmlNewSegmentedControl,
    TmlAnchor,
    TmlTextarea,
    TmlUploader,
    TmlSortableList,
    TmlImage,
    TmlPlaceholder,
    TmlButton,
  },

  mixins: [mixin],

  inject: ['app'],

  data() {
    return {
      /**
       * Icons
       */
      faImages,

      submitInProgress: false,

      /**
       * List of uploaded images
       */
      uploadedImages: [],

      /**
       * Max number of allowed images
       */
      maxAttachments: 3,

      type: {
        text: 'Issue',
        value: 'issue',
      },

      feedbackTypes: [
        {
          text: 'Issue',
          value: 'issue',
        },
        {
          text: 'Suggestion',
          value: 'suggestion',
        },
        {
          text: 'Question',
          value: 'question',
        },
      ],
    };
  },

  computed: {
    formValid() {
      return (
        this.type &&
        this.$store.state.forms &&
        this.$store.state.forms.feedbackForm &&
        this.$store.state.forms.feedbackForm.feedbackText?.isValid &&
        (this.app.isAtlas && this.type === 'issue'
          ? this.$store.state.forms.feedbackForm.proposal?.isValid
          : true)
      );
    },

    formData() {
      return this.$store.getters['forms/feedbackForm/formData'];
    },

    placeholderByType() {
      switch (this.type.value) {
        case 'suggestion':
          return "We'd love to hear about your idea, please share it";
        case 'issue':
          return 'Describe the bug, issue or problem';
        case 'question':
          return 'Describe your question';
        default:
          return 'Describe your feedback';
      }
    },
  },

  mounted() {
    $eventBus.on('anchor-open-feedback-popup', this.openPopup);
  },

  unmounted() {
    $eventBus.off('anchor-open-feedback-popup', this.openPopup);
  },

  methods: {
    maxDevice,

    reset() {
      this.submitInProgress = false;
    },

    submitFeedback() {
      if (this.submitInProgress) {
        return;
      }

      if (this.formValid) {
        this.submitInProgress = true;

        this.axios
          .post(formatUrl('/omnis/v3/feedback/submit/'), {
            type: this.type.value,
            feedback: this.formData.feedbackText.value,
            proposal: this.formData.proposal.value,
            page: this.$route.path,
            images: this.uploadedImages,
          })
          .then(() => {
            this.$overlays.close('feedback-popup');

            this.submitInProgress = false;
            this.type = {text: 'Issue', value: 'issue'};
            this.uploadedImages = [];

            this.$dialogs.showSnackBar({
              message: 'Successfully submitted',
              timeout: 5000,
              actionText: 'Write another',
              action: () => {
                this.$overlays.open('feedback-popup');
              },
            });
            this.$emit('submitted');
          })
          .catch(() => {
            this.$dialogs.showSnackBar({
              type: 'error',
              message: 'Sorry, something went wrong, please try again later',
            });

            this.submitInProgress = false;
          });
      }
    },

    closePopup() {
      this.type = {text: 'Issue', value: 'issue'};
      this.submitInProgress = false;
      this.$overlays.close('feedback-popup');
    },

    openPopup() {
      this.$overlays.open('feedback-popup');
    },
  },
};
</script>
