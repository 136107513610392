import TmlApiClient from './tmlApiClient';
import {Fulfillment} from './types/fulfillment';
import {Shipment} from './types/shipment';

class FulfillmentsApi extends TmlApiClient<
  Fulfillment,
  Partial<Fulfillment>,
  Partial<Fulfillment>
> {
  constructor() {
    super('/fulfillments');
  }

  public async getShipment(id: string): Promise<Shipment> {
    return (await this.get(`${this.resourcePath}/${id}/shipment`))
      .data as Shipment;
  }

  public async createShipment(id: string): Promise<Shipment> {
    return (await this.post(`${this.resourcePath}/${id}/shipment`))
      .data as Shipment;
  }
}

export const fulfillmentsApi = new FulfillmentsApi();
