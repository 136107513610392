import {ApiResponse} from '@teemill/common/classes';
import TmlApiClient from './tmlApiClient';
import {ConfirmOrderRequest} from './types/confirmOrderRequest';
import {CreateOrderRequest} from './types/createOrderRequest';
import {Order} from './types/order';

class OrdersApi extends TmlApiClient<
  Order,
  CreateOrderRequest,
  Partial<Order>
> {
  constructor() {
    super('/orders');
  }

  public async confirm(id: string, data: ConfirmOrderRequest): Promise<Order> {
    return (await this.post(`${this.resourcePath}/${id}/confirm`, data))
      .data as Order;
  }

  public async export(start: Date, end: Date): Promise<ApiResponse> {
    return await this.get(`${this.resourcePath}/export`, {
      params: {start, end},
    });
  }
}

export const ordersApi = new OrdersApi();
