import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/analytics/',
    name: 'analytics',
    meta: {
      title: 'Analytics',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      integration: 'website',
      access: [hasIntegration],
      layout: 'standard',
      allowPwaReload: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "PageAnalytics" */ './AnalyticsPage.vue'
      ).catch(handleFailedChunk),
  },
];
