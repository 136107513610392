import TmlApiClient from './tmlApiClient';
import {Product} from './types/product';
import {Variant} from './types/variant';

class ProductsApi extends TmlApiClient<
  Product,
  Partial<Product>,
  Partial<Product>
> {
  constructor() {
    super('/catalog/products');
  }
}

class VariantsApi extends TmlApiClient<
  Variant,
  Partial<Variant>,
  Partial<Variant>
> {
  constructor() {
    super('/catalog/variants');
  }
}

export const catalogApi = {
  products: new ProductsApi(),
  variants: new VariantsApi(),
};
