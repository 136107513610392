import {AccessStatement} from '@teemill/common/classes';
import {useProjectStore} from '../stores/project';

export const isLoggedIn = new AccessStatement(
  'isLoggedIn',
  store => !!store.state.user,
  (store, route) => `/login/?redirect_uri=${route.fullPath}`
);

export const isVerified = new AccessStatement(
  'isVerified',
  store => !!store.state.user?.verified,
  '/sign-up/verify/',
  [isLoggedIn]
);

export const isNotVerified = new AccessStatement(
  'isNotVerified',
  store => !store.state.user?.verified,
  '/dashboard/',
  [isLoggedIn]
);

export const hasSignEula = new AccessStatement(
  'hasSignEula',
  store => !!store.state.user?.signedEula,
  '/sign-up/eula/',
  [isLoggedIn]
);

export const hasProject = new AccessStatement(
  'hasProject',
  (store, to, project) => {
    let p = project;

    if (project === undefined && typeof to?.query?.project === 'string') {
      p = to.query.project;
    }

    if (typeof p !== 'string') {
      return false;
    }

    return !!useProjectStore().find(p);
  },
  store => {
    if (store.state.user && store.state.user.shouldHaveStore) {
      return '/sign-up/create-store/';
    }

    return '/sign-up/create-manufacturer/';
  },
  [isLoggedIn]
);

export const hasDashboardAccess = [isVerified, hasSignEula, hasProject];

export const isLoggedOut = new AccessStatement(
  'isLoggedOut',
  store => !store.state.user,
  '/dashboard/'
);

export const hasIntegration = new AccessStatement(
  'hasIntegration',
  (store, to, project) => {
    let p = project;

    if (project === undefined && typeof to?.query?.project === 'string') {
      p = to.query.project;
    }

    if (typeof p !== 'string' || to?.meta?.integration === undefined) {
      return false;
    }

    return !!useProjectStore()
      .find(p)
      ?.hasIntegration(to?.meta.integration as any);
  },
  '/dashboard/',
  hasDashboardAccess
);

export const isPro = new AccessStatement(
  'isPro',
  (store, to, project) => {
    let p = project;

    if (project === undefined && typeof to?.query?.project === 'string') {
      p = to.query.project;
    }

    if (typeof p !== 'string') {
      return false;
    }

    return !!useProjectStore().find(p)?.isPro;
  },
  '/teemill-plans/',
  [isLoggedIn]
);

export const hasDashboardPermissions = new AccessStatement(
  'hasDashboardPermissions',
  (store, to, project) => {
    let p = project;

    if (project === undefined && typeof to?.query?.project === 'string') {
      p = to.query.project;
    }

    if (typeof p !== 'string') {
      return false;
    }

    const foundProject = useProjectStore().find(p);

    if (!foundProject) {
      return false;
    }

    return store.getters['auth/hasDashboardPermissions'](
      to?.meta.dashboardPermissions,
      foundProject
    );
  },
  '/403/',
  [isLoggedIn]
);

export const isInternalUser = new AccessStatement(
  'isInternalUser',
  store => !!store.state.user?.internal,
  '/dashboard/',
  [isLoggedIn]
);

export const isNotTurbo = new AccessStatement(
  'isNotTurbo',
  store => !useProjectStore().active?.isTurbo,
  '/dashboard/',
  [isLoggedIn]
);

export default {
  isLoggedIn,
  isLoggedOut,
  isVerified,
  isNotVerified,
  hasSignEula,
  hasIntegration,
  hasDashboardPermissions,
  isInternalUser,
  hasProject,
  hasDashboardAccess,
  isNotTurbo,
};
