<template>
  <two-factor-auth-settings-popup
    v-if="openTwoFactorAuthPopup"
    stage="mandatory-message"
    immutable
    :user="user"
    :start-open="openTwoFactorAuthPopup"
  />
</template>

<script>
import {defineAsyncComponent} from 'vue';

export default {
  name: 'MandatoryTwoFactorAuthPopup',

  components: {
    TwoFactorAuthSettingsPopup: defineAsyncComponent(() =>
      import('./TwoFactorAuthSettingsPopup.vue')
    ),
  },

  props: {
    force2fa: Boolean,
    user: {
      type: Object,
      required: true,
    },
    auth: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      openTwoFactorAuthPopup: false,
    };
  },

  watch: {
    $route: {
      immediate: false,
      handler() {
        if (
          (this.user?.internal || this.force2fa) &&
          !this.user?.twoFactorAuthEnabled &&
          !['login', 'settings', 'home'].includes(this.$route.name)
        ) {
          this.openTwoFactorAuthPopup = true;
          this.$overlays.open('two-factor-auth-settings-popup');
        }
      },
    },
  },
};
</script>
