<script setup lang="ts">
import {reactive, computed, onMounted, onUnmounted} from 'vue';
import {useRouter} from 'vue-router';
import {
  faShirt,
  faBrowser,
  faObjectGroup,
  faCloud,
  faEnvelope,
  faMagnifyingGlass,
} from '@fortawesome/pro-light-svg-icons';
import {overlays} from '@teemill/modules/overlays';
import {events} from '@teemill/common/services';
import {AppTheme} from '@teemill/modules/app';
import {themes} from '@teemill/modules/theme-builder';
import {splitTests} from '@teemill/common/classes';
import {useSaas} from '../useSaas';
import generalBanner from '../assets/img/upgrade/general.png';
import oldProBanner from '../assets/img/upgrade-to-pro-min.jpeg';

withDefaults(
  defineProps<{
    immutable?: boolean;
    name?: string;
  }>(),
  {
    name: 'upgrade-to-pro',
  }
);

const router = useRouter();

const {upgrade, subscribe} = useSaas();

const displayContent = reactive({
  title: 'Ready for more?',
  description:
    'Upgrade to Pro to unlock more designs, more products, plugins, AI mockups, plus advanced marketing features and support to help you grow faster. Take your brand to the next level with Pro, from only £8.25/month. Cancel anytime.',
  image: generalBanner,
});

const openProUpgradePopup = ({
  title = 'Ready for more?',
  description = 'Upgrade to Pro to unlock more designs, more products, plugins, AI mockups, plus advanced marketing features and support to help you grow faster. Take your brand to the next level with Pro, from only £8.25/month. Cancel anytime.',
  image = generalBanner,
}: {
  title: string;
  description: string;
  image: string;
}) => {
  displayContent.title = title;
  displayContent.description = description;
  displayContent.image = image;

  // To start the test session as split-test state is not ready when mounted in App.vue
  splitTests.getActiveVariationFor('pro_upgrade_popup');
  overlays.open('upgrade-to-pro');
};

const openProUpgradePopupFromActionLink = () => {
  upgrade.openPopup({
    plan: 'pro',
    origin: 'action-link',
  });
};

const features = computed(() => {
  return {
    firstColumn: [
      {
        icon: faShirt,
        title: '9999 Products',
      },
      {
        icon: faObjectGroup,
        title: '999 Custom landing pages',
      },
      {
        icon: faCloud,
        title: '10 GB Drive storage',
      },
    ],
    secondColumn: [
      {
        icon: faBrowser,
        title: 'Custom domain',
      },
      {
        icon: faMagnifyingGlass,
        title: 'SEO Tools',
      },
      {
        icon: faEnvelope,
        title: 'Send marketing emails',
      },
    ],
  };
});

const goToTeemillPlans = () => {
  overlays.close('upgrade-to-pro');
  router.push('/teemill-plans');
};

onMounted(() => {
  events.on('anchor-learn-more-about-pro', openProUpgradePopupFromActionLink);
  events.on('open-upgrade-to-pro-popup', openProUpgradePopup);
});

onUnmounted(() => {
  events.off('anchor-learn-more-about-pro', openProUpgradePopupFromActionLink);
  events.off('open-upgrade-to-pro-popup', openProUpgradePopup);
});
</script>

<template>
  <tml-split-test name="pro_upgrade_popup">
    <tml-split-test-variation name="new">
      <tml-new-popup
        ref="popup"
        :name="name"
        :title="displayContent.title"
        layout="standard"
        size="normal"
        height="auto"
        :immutable="immutable"
        disable-route-changes
      >
        <template #content>
          <app-theme :theme="themes.teemillPro">
            <tml-image
              :src="displayContent.image"
              class="mb-4"
              :ratio="0.4"
              alt="Get Teemill Pro"
            />
            <p class="mb-4">
              {{ displayContent.description }}
              <tml-anchor
                text="See all features."
                force-pro
                @click="goToTeemillPlans"
              />
            </p>
            <h5 class="mb-0">Here's whats included in Teemill Pro:</h5>

            <div
              class="py-3 px-1 grid sm:grid-cols-2 gap-y-2 sm:gap-x-3 sm:gap-y-0"
            >
              <ul class="space-y-3 lg:space-y-4 text-sm sm:text-base">
                <li
                  v-for="{icon, title} in features.firstColumn"
                  :key="title"
                  class="flex space-x-3 items-center"
                >
                  <font-awesome-icon
                    :icon="icon"
                    class="flex-shrink-0 text-2xl w-8 text-[--tml-primary-color]"
                  />
                  <p class="text-base lg:text-lg">
                    {{ title }}
                  </p>
                </li>
              </ul>
              <ul class="space-y-3 lg:space-y-4 text-sm sm:text-base">
                <li
                  v-for="{icon, title} in features.secondColumn"
                  :key="title"
                  class="flex space-x-3 items-center"
                >
                  <font-awesome-icon
                    :icon="icon"
                    class="flex-shrink-0 text-2xl w-8 text-[--tml-primary-color]"
                  />
                  <p class="text-base lg:text-lg">
                    {{ title }}
                  </p>
                </li>
              </ul>
            </div>
          </app-theme>
        </template>
        <template #footer>
          <tml-button
            text="Upgrade"
            fill
            force-pro
            primary
            @click="subscribe.openPopup()"
          />
        </template>
      </tml-new-popup>
    </tml-split-test-variation>

    <tml-split-test-variation name="control" default>
      <tml-new-popup
        ref="popup"
        :name="name"
        title="Ready for more?"
        layout="standard"
        size="normal"
        height="auto"
        :immutable="immutable"
        disable-route-changes
      >
        <template #content>
          <tml-anchor href="/teemill-plans/">
            <tml-image :src="oldProBanner" class="mb-4" alt="Get Teemill Pro" />
          </tml-anchor>

          <p class="mb-4">
            Upgrade to Pro to unlock more designs, more products, more AI
            mockups, and advanced marketing features, plugins and support to
            help you sell and scale faster. Take your brand to the next level
            and try Pro now for only £10/month. Cancel anytime.
          </p>

          <tml-item-tray>
            <template #primary>
              <tml-button
                text="Upgrade"
                fill
                force-pro
                primary
                @click="subscribe.openPopup()"
              />
            </template>
            <template #secondary>
              <tml-button
                text="Find out more"
                href="/teemill-plans/"
                target="_blank"
                fill
                @click="overlays.close('upgrade-to-pro')"
              />
            </template>
          </tml-item-tray>
        </template>
      </tml-new-popup>
    </tml-split-test-variation>
  </tml-split-test>
</template>
