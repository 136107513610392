import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/template-builder/',
    name: 'template-builder.select-product',
    meta: {
      title: 'Template builder',
      breadcrumb: {
        type: 'page',
        parent: 'products',
      },
      access: hasDashboardAccess,
      layout: 'standard',
    },
    component: () =>
      import(
        /* webpackChunkName: "PageTemplateBuilder" */ './TemplateBuilderProductList.vue'
      ).catch(handleFailedChunk),
  },
  {
    path: '/template-builder/:product',
    name: 'template-builder.configurer',
    meta: {
      title: 'Template builder',
      breadcrumb: {
        type: 'page',
        parent: 'products',
      },
      access: hasDashboardAccess,
      layout: 'standard',
      cloudRedirect: to => {
        return {
          name: 'catalog.legacy-template',
          params: {
            id: to.params.product,
          },
        };
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageTemplateBuilder" */ './TemplateBuilderConfigurer.vue'
      ).catch(handleFailedChunk),
  },
];
