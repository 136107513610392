import {
  ApiError,
  NotFoundError,
  ServerError,
  ValidationError,
} from '@teemill/common/errors';

export class SubscriptionServerError extends ServerError {
  constructor(message = 'Server error encountered fetching subscription') {
    super(message);
  }
}

export class SubscriptionNotFoundError extends NotFoundError {
  constructor(message = 'Subscription not found') {
    super(message);
  }
}

export class SubscriptionSetupFailedError extends ApiError {
  constructor(message = 'Subscription setup failed') {
    super(message);
  }
}

export class SubscriptionValidationError extends ValidationError {
  constructor(message = 'Subscription failed validation') {
    super(message);
  }
}
