import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration, hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/homepage-editor/',
    redirect: '/pages/edit/home/',
    meta: {
      search: {
        show: false,
      },
    },
  },
  {
    path: '/store/',
    redirect: '/website/',
    meta: {
      search: {
        show: false,
      },
    },
  },
  {
    name: 'page-editor',
    path: '/pages/',
    meta: {
      title: 'Pages',
      integration: 'website',
      access: [hasIntegration],
      layout: 'standard',
    },
    component: () => import('./PageEditorIndex.vue').catch(handleFailedChunk),
    children: [
      {
        path: '',
        name: 'pages.list',
        meta: {
          title: 'My Pages',
          breadcrumb: {
            type: 'page',
            parent: 'website',
          },
          layout: 'standard',
          cloudRedirect: () => {
            return {
              name: 'website.pages.list',
            };
          },
        },
        component: () => import('./StorePages.vue').catch(handleFailedChunk),
      },
      {
        path: 'create/',
        component: () =>
          import('./PageEditorPage.vue').catch(handleFailedChunk),
        meta: {
          title: 'Create Page',
          dashboardPermissions: ['edit-webpage'],
          access: [hasDashboardPermissions],
          layout: 'tool',
          cloudRedirect: to => {
            return {
              name: 'website.pages.create',
              query: {
                template: to.query.template,
                duplicate: to.query.duplicate,
              },
            };
          },
        },
      },
      {
        path: 'edit/:page/',
        name: 'pages.edit',
        component: () =>
          import('./PageEditorPage.vue').catch(handleFailedChunk),
        meta: {
          title: 'Edit Page',
          dashboardPermissions: ['edit-webpage'],
          access: [hasDashboardPermissions],
          layout: 'tool',
          cloudRedirect: to => {
            return {
              name: 'website.pages.edit',
              params: {
                id: to.params.page,
              },
            };
          },
        },
      },
      {
        path: 'preview/:layout/:page/',
        component: () =>
          import('./PagePreviewPage.vue').catch(handleFailedChunk),
        meta: {
          title: 'Preview Page',
          layout: 'blank',
        },
      },
    ],
  },
];
