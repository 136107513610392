<template>
  <tml-form ref="form" form-name="update-username">
    <slot name="title" />
    <tml-editable-image
      :style="{maxWidth: '194px'}"
      class="my-8 mx-auto"
      template="avatar"
      :src="
        formData.avatar && typeof formData.avatar === 'object'
          ? formData.avatar.size('avatar')
          : formData.avatar
      "
      image-uploader
      :content-division="app.isAtlas ? 2 : null"
      @edited="({image}) => (formData.avatar = image)"
    >
      <template #preview>
        <tml-image
          class="rounded-full"
          alt="Your avatar, this will be displayed as your photo in chat channels"
          title="Your avatar, this will be displayed as your photo in chat channels"
          :src="
            formData.avatar && typeof formData.avatar === 'object'
              ? formData.avatar.size('avatar')
              : formData.avatar
          "
        />
      </template>
    </tml-editable-image>
    <tml-input
      v-model="formData.username"
      class="mb-4"
      placeholder="Username"
      type="text"
      field-name="username"
      :validation-rules="{
        required: true,
        alpha: true,
        min_length: 2,
        max_length: 50,
      }"
      :auto-complete="false"
      @on-submit="submit"
    />
    <p v-if="app.isAtlas" class="mb-4">
      Set a username and image for your community profile.
    </p>
    <p v-else class="mb-4">
      Set a username and image for your community profile. We strongly recommend
      an anonymous ID, not your real name or brand.
      <tml-anchor
        text="Our community values"
        href="/policy/community-values/"
        target="_blank"
      />
    </p>
    <tml-button text="Update" :loading="loading" fill primary @click="submit" />
  </tml-form>
</template>

<script>
import {formatUrl} from '@teemill/common/helpers';
import {TmlEditableImage} from '@teemill/modules/images';

export default {
  name: 'UpdateUsernameForm',

  components: {
    TmlEditableImage,
  },

  inject: ['app'],

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,

      formData: {},
    };
  },

  created() {
    const {username, avatar} = this.user || {};

    this.formData = {username, avatar};
  },

  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      const valid = await this.$refs.form.validateAll();

      if (!valid) {
        return;
      }

      this.loading = true;

      const request = this.axios
        .post(
          formatUrl(
            this.app.isAtlas
              ? '/omnis/v3/atlas/users/update'
              : '/omnis/v3/shop/customer/update/'
          ),
          {
            username: this.formData.username,
            avatar:
              typeof this.formData.avatar === 'string'
                ? this.formData.avatar
                : undefined,
          }
        )
        .validation()
        .oops()
        .finally(() => {
          this.loading = false;
        });

      if (this.app.isAtlas) {
        request.success(({username, avatar}) => {
          snackbar('Saved');

          this.$store.commit('auth/setUserVariable', {
            key: 'username',
            value: username,
          });

          this.$store.commit('auth/setUserVariable', {
            key: 'avatar',
            value: avatar,
          });

          this.$emit('done');
        });
      } else {
        request.success(user => {
          snackbar('Saved');

          this.$store.dispatch('insertInitialState', {user});

          this.$emit('done');
        });
      }
    },
  },
};
</script>
