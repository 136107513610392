import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/marketing/discounts/',
    name: 'discounts',
    meta: {
      title: 'Discounts',
      breadcrumb: {
        type: 'page',
        parent: 'marketing',
      },
      integration: 'website',
      access: [hasIntegration],
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: () =>
      import('./components/DiscountsPage.vue').catch(handleFailedChunk),
  },
];
