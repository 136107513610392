import {defineAsyncComponent} from 'vue';

/**
 * TODO:
 * Deprecate TmlImageCropper and replace with ImageFocalEditor to remove OmniCanvas dependencies.
 * Move everything in this module back to @teemill/components.
 */
export const TmlImageCropper = defineAsyncComponent(
  () => import('./components/TmlImageCropper.vue')
);
export const TmlImageUploader = defineAsyncComponent(
  () => import('./components/TmlImageUploader.vue')
);
export const TmlImageFilterPopup = defineAsyncComponent(
  () => import('./components/TmlImageFilterPopup.vue')
);
export const TmlImageFilterEditor = defineAsyncComponent(
  () => import('./components/tml-image-filter-editor/TmlImageFilterEditor.vue')
);
export const TmlEditableImage = defineAsyncComponent(
  () => import('./components/TmlEditableImage.vue')
);
export const TmlNewEditableImage = defineAsyncComponent(
  () => import('./components/TmlNewEditableImage.vue')
);
