import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration, hasDashboardPermissions} from '../../guards';
import {RouteRecordRaw} from 'vue-router';

export default [
  {
    path: '/menu-editor/',
    name: 'menu-editor',
    meta: {
      title: 'Menu',
      breadcrumb: {
        type: 'page',
        parent: 'website',
      },
      integration: 'website',
      dashboardPermissions: ['edit-webpage'],
      access: [hasIntegration, hasDashboardPermissions],
      layout: 'standard',
      search: {
        icon: 'bars',
      },
    },
    component: () =>
      import(/* webpackChunkName: "PageMenuEditor" */ './MenuEditor.vue').catch(
        handleFailedChunk
      ),
  },
] as RouteRecordRaw[];
