import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration, isPro, hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/marketing-tools/notifications/',
    name: 'push-notifications',
    meta: {
      title: 'Push Notifications',
      integration: 'website',
      access: [hasIntegration, isPro],
      layout: 'standard',
    },
    component: () =>
      import(/* webpackChunkName: "PagePush" */ './PushIndex.vue').catch(
        handleFailedChunk
      ),
    children: [
      {
        path: '',
        name: 'push-notifications-home',
        meta: {
          title: 'Push Notifications',
          layout: 'standard',
          dashboardPermissions: ['send-push'],
          access: [hasDashboardPermissions],
        },
        component: () =>
          import(
            /* webpackChunkName: "PagePush" */ './PushDashboard.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'builder',
        name: 'push-notifications-builder',
        meta: {
          title: 'Create Notification',
          layout: 'standard',
          dashboardPermissions: ['send-push'],
          access: [hasDashboardPermissions],
        },
        component: () =>
          import(/* webpackChunkName: "PagePush" */ './PushBuilder.vue').catch(
            handleFailedChunk
          ),
      },
    ],
  },
];
