import {castArray} from 'lodash';
import {useProjectStore} from '../stores/project';

export default {
  namespaced: true,

  state: {
    active: null,
    stores: [],
  },

  getters: {
    storeApproved: state => {
      if (state.approval && state.approval.status === 'approved') {
        return true;
      }

      return false;
    },

    headerHex: state => (state.theme ? state.theme.colours.header : '#ffffff'),

    findByManufacturer: state => id =>
      state.stores.find(store => store.projectId === id),
  },

  actions: {
    /**
     * @name Update Stores
     * @description Updates the stores attached to the logged in user and sets active
     */
    update({state, commit, dispatch}, stores = []) {
      stores = castArray(stores);

      const setActive = state.stores.length === 0;
      const existingStores = state.stores.map(s => s.id);

      commit(
        'update',
        stores.filter(store => existingStores.includes(store.id))
      );

      commit(
        'add',
        stores.filter(store => store.id && !existingStores.includes(store.id))
      );

      if (setActive) {
        const projectStore = useProjectStore();

        dispatch(
          'active',
          state.stores.find(
            store => store.projectId === projectStore.active?.id
          )
        );
      }
    },

    active({commit}, store) {
      commit('active', store);
    },
  },

  mutations: {
    /**
     * @name Update Stores
     * @description Updates the stores attached to the logged in user
     */
    update(state, stores) {
      stores.forEach(store => {
        state.stores
          .filter(s => s.id === store.id)
          .forEach(s => {
            Object.assign(s, store);
          });
      });
    },

    /**
     * @name Add Store
     * @description Adds a store attached to the logged in user
     */
    add(state, stores) {
      state.stores.push(...stores);
    },

    /**
     * @name Set Active Store
     * @description Sets the active store
     */
    active(state, store) {
      state.active = state.stores.find(s => s.id === store?.id);
    },

    /**
     * @name Reset store state
     * @description Sets the store state back to default
     */
    reset(state) {
      state.stores = [];
      state.active = null;
    },
  },
};
