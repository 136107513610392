<script setup lang="ts">
import {ref, onMounted, onBeforeUnmount, watch} from 'vue';
import {captureException} from '@sentry/vue';
import {Stripe, StripePaymentElement, StripeElements} from '@stripe/stripe-js';
import {faCreditCardFront} from '@fortawesome/pro-light-svg-icons';
import {StripePaymentElementReadyPayload} from '../types';
import {useStore} from 'vuex';

const props = defineProps<{
  clientSecret?: string;
  amount?: number;
  mode?: 'payment' | 'setup' | 'subscription';
}>();

const emit = defineEmits<{
  (e: 'ready', payload: StripePaymentElementReadyPayload): void;
}>();

const stripe = ref<Stripe>();
const elements = ref<StripeElements>();
const paymentElement = ref<StripePaymentElement>();
const errorOccurred = ref<boolean>(false);

const store = useStore();

const initialisePaymentElement = async () => {
  try {
    initialiseStripe(import.meta.env.VITE_STRIPE_PK);

    createStripeElements();
  } catch (error) {
    errorOccurred.value = true;

    captureException(error);
  }
};

const createStripeElements = () => {
  const createWithIntent = !!props.clientSecret;

  if (createWithIntent) {
    createPaymentElementWithIntent();
  } else {
    createPaymentElementWithoutIntent();
  }
};

const createPaymentElementWithIntent = () => {
  if (!stripe.value) {
    return;
  }

  elements.value = stripe.value.elements({clientSecret: props.clientSecret});

  paymentElement.value = elements.value.create('payment', {
    terms: {
      card: 'never',
    },
  });

  paymentElement.value.mount('#payment-element');

  emit('ready', {
    stripe: stripe.value,
    elements: elements.value,
    paymentElement: paymentElement.value,
  });
};

const createPaymentElementWithoutIntent = () => {
  if (!stripe.value) {
    return;
  }

  elements.value = stripe.value.elements({
    mode: props.mode ?? 'subscription',
    amount: props.amount,
    currency: (store?.state?.subdomain?.currency ?? 'GBP').toLowerCase(),
    payment_method_types: ['card'],
  });

  paymentElement.value = elements.value.create('payment', {
    terms: {
      card: 'never',
    },
  });

  paymentElement.value.mount('#payment-element');

  paymentElement.value.collapse();

  emit('ready', {
    stripe: stripe.value,
    elements: elements.value,
    paymentElement: paymentElement.value,
  });
};

const initialiseStripe = (publishableKey: string): void => {
  if (!window.Stripe) {
    throw new Error('Stripe is not loaded');
  }

  stripe.value = window.Stripe(publishableKey) as any;
};

const reloadPage = () => {
  window.location.reload();
};

onMounted(async () => {
  await initialisePaymentElement();
});

onBeforeUnmount(() => {
  if (paymentElement.value) {
    paymentElement.value.destroy();
  }
});

watch(
  () => props.amount,
  async newAmount => {
    if (elements.value) {
      elements.value.update({
        amount: newAmount,
      });
    }
  }
);
</script>

<template>
  <div v-if="!errorOccurred" id="payment-element" />
  <p v-else class="stripe-error form mb-4">
    <font-awesome-icon class="mr-1" :icon="faCreditCardFront" />
    Failed to load payment element.
    <tml-anchor text="Try refreshing the page." @click="reloadPage" />
  </p>
</template>
