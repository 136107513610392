import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/custom-product-terms-of-service/',
    name: 'custom-product-terms-of-service',
    meta: {
      title: 'Custom Product Terms of Service',
      layout: 'standard',
      showOnSiteMap: true,
      search: {
        show: false,
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageCustomProductTerms" */ './CustomProductTerms.vue'
      ).catch(handleFailedChunk),
  },
];
