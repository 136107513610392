import {handleFailedChunk} from '@teemill/common/helpers';
import {isLoggedIn, isLoggedOut, isNotVerified} from '../../guards';
import TowelCapeImage from './assets/towel-cape.min.jpg';
import OutermostImage from './assets/outermost-quote.jpg';
import MoonQuote from './assets/moon-quote.jpg';
import TfcQuote from './assets/tfc-quote.jpg';

export default [
  {
    path: '/sign-up/',
    alias: ['/register/'],
    name: 'sign-up',
    meta: {
      title: 'Sign Up',
      layout: 'blank',
      showOnSiteMap: true,
      search: {
        show: false,
      },
    },
    component: () =>
      import(/* webpackChunkName: "PageSignUp" */ './SignUp.vue').catch(
        handleFailedChunk
      ),
    redirect: {name: 'sign-up.brand'},
    children: [
      {
        path: 'brand/',
        name: 'sign-up.brand',
        meta: {
          title: 'Create Brand',
          layout: 'blank',
          layoutSplitImage: OutermostImage,
          layoutSplitRatio: 0.5,
          layoutSplitQuote:
            '"Starting and growing a brand with Teemill has been so much fun"',
          layoutSplitAttribution: 'Andy - founder of Outermost Apparel',
          showOnSiteMap: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpBrandName.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'create-login/',
        name: 'sign-up.create-user',
        meta: {
          title: 'Create Login',
          access: [isLoggedOut],
          layout: 'blank',
          layoutSplitImage: MoonQuote,
          layoutSplitRatio: 0.5,
          layoutSplitQuote:
            '"All my T-shirts made by Teemill have lasted years and the quality is exceptional. I love it."',
          layoutSplitAttribution: 'Jo - Founder of The Moon Journal',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpCreateUser.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'set-password/',
        name: 'sign-up.set-password',
        meta: {
          title: 'Set Password',
          access: [isLoggedOut],
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0.5,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpSetPassword.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'demographic/',
        name: 'sign-up.demographic',
        meta: {
          title: 'Select Demographic',
          access: [isLoggedIn],
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpDemographic.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'custom-demographic/',
        name: 'sign-up.custom-demographic',
        meta: {
          title: 'Enter Demographic',
          access: [isLoggedIn],
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpCustomDemographic.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'create-store/',
        alias: ['/create-store/'],
        name: 'sign-up.create-store',
        meta: {
          title: 'Create Store',
          access: [isLoggedIn],
          layout: 'blank',
          layoutSplitImage: TfcQuote,
          layoutSplitRatio: 0.5,
          layoutSplitQuote:
            '"Beautifully printed, high quality, sustainable T-shirts that our customers are proud to wear"',
          layoutSplitAttribution: 'Sophie - Founder of T-Shirts For Change',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpCreateStore.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'create-manufacturer/',
        alias: ['/create-manufacturer/'],
        name: 'sign-up.create-manufacturer',
        meta: {
          title: 'Create Manufacturer',
          access: [isLoggedIn],
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0.5,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpCreateManufacturer.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'verify/',
        alias: ['/verify-account/'],
        name: 'sign-up.verify',
        meta: {
          title: 'Verify',
          access: [isLoggedIn, isNotVerified],
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0.5,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpVerifyEmail.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'verify/:hash/',
        alias: ['/verify-account/:hash/'],
        name: 'sign-up.verify-landing',
        meta: {
          title: 'Verify',
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0.5,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpVerifyEmailLanding.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'eula/',
        alias: ['/terms-and-conditions/'],
        name: 'sign-up.eula',
        meta: {
          title: 'Eula',
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0.25,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpEula.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'eula/appendix/',
        alias: ['/terms-and-conditions/appendix/'],
        name: 'sign-up.eula.appendix',
        meta: {
          title: 'Eula',
          layout: 'blank',
          layoutSplitImage: TowelCapeImage,
          layoutSplitRatio: 0.25,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpEulaAppendix.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'woocommerce/',
        name: 'sign-up.woocommerce',
        meta: {
          title: 'Connect to WooCommerce',
          layout: 'blank',
          layoutSplitImage: TfcQuote,
          layoutSplitRatio: 0.5,
          layoutSplitQuote:
            '"Beautifully printed, high quality, sustainable T-shirts that our customers are proud to wear"',
          layoutSplitAttribution: 'Sophie - Founder of T-Shirts For Change',
          showOnSiteMap: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "PageSignUp" */ './partials/SignUpWooCommerce.vue'
          ).catch(handleFailedChunk),
      },
    ],
  },
];
