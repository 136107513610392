<template>
  <tml-new-popup
    name="global-update-username-form"
    layout="standard"
    size="normal"
    height="auto"
    title="Join the Conversation"
  >
    <template #content>
      <update-username-form
        :user="user"
        @done="overlays.close('global-update-username-form')"
      />
    </template>
  </tml-new-popup>
</template>

<script>
import UpdateUsernameForm from './UpdateUsernameForm.vue';
import {overlays} from '@teemill/modules/overlays';

export default {
  name: 'UpdateUsernamePopup',
  components: {
    UpdateUsernameForm,
  },
  props: {
    user: {
      required: true,
    },
  },
  data() {
    return {
      overlays,
    };
  },
};
</script>
