import {RouteRecordRaw} from 'vue-router';

import {
  BlogIndex,
  BlogPost,
  BlogSeriesIndex,
  BlogSeriesShowIndex,
  BlogSeriesShow,
} from '@teemill/modules/blog/frontend/components/pages';

const routes: RouteRecordRaw[] = [
  {
    path: '/blog/',
    alias: ['/learn/'],
    props: {
      divisions: [12], // Teemill
    },
    meta: {
      title: 'Academy',
      layout: 'standard',
      search: {
        icon: 'graduation-cap',
        customSearchTerm: 'academy',
      },
      allowPwaReload: true,
    },
    component: BlogIndex,
    children: [
      {
        path: '',
        name: 'blog.dashboard',
        meta: {
          title: 'Search',
          layout: 'standard',
          showOnSiteMap: true,
          breadcrumb: {
            type: 'page',
            parent: 'academy',
          },
          allowPwaReload: true,
        },
        component: () => import('./BlogAdvancedDashboardContainer.vue'),
      },
      {
        path: 'tagged/:tag?/',
        redirect: to => ({
          name: 'blog.dashboard',
          query: {
            search: to.params.tag,
          },
        }),
      },
      {
        path: 'series',
        meta: {
          title: 'Academy',
          layout: 'standard',
        },
        component: BlogSeriesIndex,
        children: [
          {
            path: '',
            redirect: () => ({name: 'blog.dashboard'}),
          },
          {
            path: ':seriesUrl',
            meta: {
              title: 'Academy',
              layout: 'standard',
            },
            props: true,
            component: BlogSeriesShowIndex,
            children: [
              {
                path: '',
                name: 'blog.series.show',
                meta: {
                  title: 'Academy',
                  breadcrumb: {
                    type: 'url-param',
                    param: 'seriesUrl',
                    parent: 'blog.dashboard',
                  },
                  layout: 'standard',
                },
                props: true,
                component: BlogSeriesShow,
              },
            ],
          },
        ],
      },
      {
        path: ':post/',
        name: 'blog-post',
        meta: {
          title: 'Academy',
          breadcrumb: {
            type: 'blog-post',
            parent: 'academy',
          },
          layout: 'standard',
        },
        component: BlogPost,
      },
    ],
  },
];

export default routes;
