<template>
  <div
    class="tml-dynamic-overlay-container"
    style="height: 0px; overflow: hidden"
  >
    <component
      :is="overlay.component"
      v-for="overlay in overlays"
      :key="overlay.uid"
      :name="overlay.uid"
      :uid="overlay.uid"
      start-open
      v-bind="overlay.props"
      v-on="overlay.events"
      @close="onRemove(overlay)"
    />
  </div>
</template>

<script>
import {v4 as uuid} from 'uuid';
import {defineAsyncComponent} from 'vue';
import {overlays} from '../overlays';

export default {
  name: 'TmlDynamicOverlayContainer',

  components: {
    TmlConfirmPasswordPopup: defineAsyncComponent(() =>
      import('./popups/TmlConfirmPasswordPopup.vue')
    ),
    TmlConfirmPopup: defineAsyncComponent(() =>
      import('./popups/TmlConfirmPopup.vue')
    ),
    TmlCookiePopup: defineAsyncComponent(() =>
      import('./popups/TmlCookiePopup.vue')
    ),
    TmlCropperPopup: defineAsyncComponent(() =>
      import('./popups/TmlCropperPopup.vue')
    ),
    TmlDashboardRoleMatrixPopup: defineAsyncComponent(() =>
      import('./popups/TmlDashboardRoleMatrixPopup.vue')
    ),
    TmlDriveFilePopup: defineAsyncComponent(() =>
      import('./popups/TmlDriveFilePopup.vue')
    ),
    TmlGotItPopup: defineAsyncComponent(() =>
      import('./popups/TmlGotItPopup.vue')
    ),
    TmlImagePreviewPopup: defineAsyncComponent(() =>
      import('./popups/TmlImagePreviewPopup.vue')
    ),
    TmlLoadingPopup: defineAsyncComponent(() =>
      import('./popups/TmlLoadingPopup.vue')
    ),
    TmlMagicModelPopup: defineAsyncComponent(() =>
      import('./popups/TmlMagicModelPopup.vue')
    ),
    TmlPushNotSupportedPopup: defineAsyncComponent(() =>
      import('./popups/TmlPushNotSupportedPopup.vue')
    ),
    TmlRequestDashboardPermissionPopup: defineAsyncComponent(() =>
      import('./popups/TmlRequestDashboardPermissionPopup.vue')
    ),
    TmlRequestPermissionPopup: defineAsyncComponent(() =>
      import('./popups/TmlRequestPermissionPopup.vue')
    ),
    TmlSelectProductImagePopup: defineAsyncComponent(() =>
      import('./popups/TmlSelectProductImagePopup.vue')
    ),
    TmlConfirmCorrespondencePopup: defineAsyncComponent(() =>
      import('./popups/TmlConfirmCorrespondencePopup.vue')
    ),
  },

  data() {
    return {
      overlays: [],
    };
  },

  created() {
    overlays.on('add-dynamic-overlay', this.addOverlay);
  },

  beforeUnmount() {
    overlays.off('add-dynamic-overlay', this.addOverlay);
  },

  methods: {
    addOverlay(data) {
      const uid = uuid();
      data.uid = uid;

      this.overlays.push({
        uid,
        ...data,
      });
    },

    onRemove(overlay) {
      this.overlays.splice(
        this.overlays.findIndex(item => item.uid === overlay.uid),
        1
      );
    },
  },
};
</script>
