import {RouteLocationNormalized, RouteRecordRaw} from 'vue-router';

import {hasDashboardAccess} from '../../guards';

const routes: RouteRecordRaw[] = [
  {
    path: '/chat/',
    redirect: '/chat/channels/',
    meta: {
      title: 'Chat',
      access: hasDashboardAccess,
      layout: 'tool',
      search: {
        icon: 'comments',
        customSearchTerm: 'Community',
      },
    },
    component: () => import('./ChatPage.vue'),
    children: [
      {
        path: 'channels',
        name: 'chat.channels',
        props: true,
        meta: {
          title: 'Chat',
          layout: 'tool',
          search: {
            show: false,
          },
          cloudRedirect: () => {
            return {
              name: 'legacy-chat.channels',
            };
          },
        },
        component: () => import('./ChatChannelsPage.vue'),
      },
      {
        path: ':channel/:message?',
        name: 'chat.channel',
        props: true,
        meta: {
          title: 'Chat',
          layout: 'tool',
          search: {
            show: false,
          },
          cloudRedirect: (to: RouteLocationNormalized) => {
            return {
              name: 'legacy-chat.channel',
              params: {
                channel: to.params.channel,
                message: to.params.message,
              },
            };
          },
        },
        component: () => import('./ChatChannelPage.vue'),
      },
    ],
  },
];

export default routes;
