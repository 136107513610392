import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/basket/',
    name: 'Basket',
    meta: {
      title: 'Basket',
      layout: 'standard',
    },
    component: () =>
      import('./DashboardBasketPage.vue').catch(handleFailedChunk),
  },
];
