import {handleFailedChunk} from '@teemill/common/helpers';
import {isLoggedIn} from '../../guards';

export default [
  {
    path: '/module/setup',
    name: 'module.setup',
    meta: {
      title: 'Setup',
      access: [isLoggedIn],
      layout: 'blank',
    },
    component: () => import('./Setup.vue').catch(handleFailedChunk),
    beforeEnter: (to, from) => {
      if (!to.query.integration) {
        return from;
      }
    },
  },
];
