import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/drive/',
    name: 'drive',
    meta: {
      title: 'Drive',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      access: hasDashboardAccess,
      layout: 'standard',
    },
    component: () =>
      import(/* webpackChunkName: "Drive" */ './Drive.vue').catch(
        handleFailedChunk
      ),
  },
];
