import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration, hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/create-collection/',
    name: 'create-collection',
    meta: {
      title: 'Create Collection',
      breadcrumb: {
        type: 'page',
        parent: 'my-collections',
        position: 'custom',
      },
      integration: 'website',
      dashboardPermissions: ['edit-products'],
      access: [hasIntegration, hasDashboardPermissions],
      layout: 'tool',
      search: {
        icon: 'layer-group',
      },
      cloudRedirect: to => {
        return {
          name: 'website.collections.create',
          query: {
            dynamic: to.query.dynamic,
          },
        };
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageCreateCollection" */ './CreateCollection.vue'
      ).catch(handleFailedChunk),
  },
  {
    path: '/edit-collection/:collection',
    name: 'edit-collection',
    meta: {
      title: 'Edit Collection',
      breadcrumb: {
        type: 'page',
        parent: 'my-collections',
        position: 'custom',
      },
      integration: 'website',
      dashboardPermissions: ['edit-products'],
      access: [hasIntegration, hasDashboardPermissions],
      layout: 'tool',
      cloudRedirect: to => {
        return {
          name: 'website.collections.edit',
          params: {
            id: to.params.collection,
          },
        };
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageCreateCollection" */ './CreateCollection.vue'
      ).catch(handleFailedChunk),
  },
];
