import {useProjectStore} from '../stores/project';

export default {
  namespaced: true,
  getters: {
    /**
     * @deprecated
     */
    hasDashboardPermissions:
      (state, getters, rootState) =>
      (permissions, project = null) => {
        if (!rootState?.user) {
          return false;
        }

        if (!Array.isArray(permissions)) {
          permissions = [permissions];
        }

        if (!project) {
          project = useProjectStore().active;
        }

        const missing = permissions.filter(
          x => !project?.user?.permissions?.includes(x)
        );

        return missing.length === 0;
      },
  },
};
