import {pickBy} from 'lodash';
import {ApiClient} from './apiClient';

/**
 * Provides common CRUD functionality for interacting with the public-api
 */
export default class TmlApiClient<
  T,
  CreateRequest,
  UpdateRequest
> extends ApiClient {
  /**
   * @param resourcePath the path to the resource, e.g. `/orders` for orders-api
   */
  constructor(protected readonly resourcePath: string) {
    super();
  }

  private async handleRequest<T>(request: Promise<any>): Promise<T> {
    return (await request).data as T;
  }

  public async list(params?: {
    pageToken?: number;
    pageSize?: number;
    search?: string;
    ids?: string[] | string;
    statuses?: string[] | string;
    start?: Date | string;
    end?: Date | string;
    fields?: string[];
  }): Promise<T[]> {
    if (params?.start instanceof Date) {
      params.start = params.start.toISOString();
    }
    if (params?.end instanceof Date) {
      params.end = params.end.toISOString();
    }

    const result = await this.handleRequest<
      {[key: string]: T[]} & {nextPageToken: string}
    >(
      this.get(this.resourcePath, {
        params: pickBy(params, v => (v instanceof Array ? v.length > 0 : v)),
      })
    );

    return Object.values(result)[0] as T[];
  }

  public async find(id: string): Promise<T> {
    return await this.handleRequest<T>(this.get(`${this.resourcePath}/${id}`));
  }

  public async create(data: CreateRequest): Promise<T> {
    return await this.handleRequest<T>(this.post(this.resourcePath, data));
  }

  public async update(id: string, data: UpdateRequest): Promise<T> {
    return await this.handleRequest<T>(
      this.patch(`${this.resourcePath}/${id}`, data)
    );
  }

  public async trash(id: string): Promise<void> {
    await this.handleRequest<void>(this.delete(`${this.resourcePath}/${id}`));
  }
}
