export default [
  {
    path: '/403/',
    name: '403',
    meta: {
      title: '403',
      layout: 'blank',
      search: {
        show: false,
      },
    },
    component: () => import('./Dashboard403Page.vue'),
  },
  {
    path: '/404/',
    name: '404',
    meta: {
      title: '404',
      layout: 'blank',
      search: {
        show: false,
      },
    },
    component: () => import('./Dashboard404Page.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'routeNotFound',
    component: () => import('./Dashboard404Page.vue'),
    // redirect: '/404/', // can switch to use redirect if page is broken when it should 404
  },
];
