import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/shopify/store-help',
    redirect: '/integrations/store-help',
  },
  {
    path: '/integrations/store-help/',
    name: 'integrations-store-help',
    meta: {
      title: 'Help',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageStoreHelp" */ './ShopifyStoreHelp.vue'
      ).catch(handleFailedChunk),
    children: [
      {
        path: 'question/:questionName?/',
        name: 'shopify-store-question-name',
        meta: {
          title: 'Help',
          breadcrumb: {
            type: 'page',
            parent: 'dashboard',
          },
          layout: 'standard',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageStoreHelp" */ './ShopifyStoreHelp.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'reason/:reasonName?/',
        name: 'shopify-store-reason-name',
        meta: {
          title: 'Help',
          breadcrumb: {
            type: 'page',
            parent: 'dashboard',
          },
          layout: 'standard',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageStoreHelp" */ './ShopifyStoreHelp.vue'
          ).catch(handleFailedChunk),
      },
    ],
    // prefetchChunks: ['PageStoreHelp', 'TmlGlobal', 'TmlContainers'],
  },
  {
    path: '/store-help/:questionName?/',
    name: 'store-help',
    meta: {
      title: 'Help',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      layout: 'standard',
      showOnSiteMap: true,
      search: {
        icon: 'headset',
      },
    },
    component: () =>
      import(/* webpackChunkName: "PageStoreHelp" */ './StoreHelp.vue').catch(
        handleFailedChunk
      ),
    children: [
      {
        path: 'question/:questionName?/',
        name: 'store-question-name',
        meta: {
          title: 'Help',
          breadcrumb: {
            type: 'page',
            parent: 'dashboard',
          },
          layout: 'standard',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageStoreHelp" */ './StoreHelp.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'reason/:reasonName?/',
        name: 'store-reason-name',
        meta: {
          title: 'Help',
          breadcrumb: {
            type: 'page',
            parent: 'dashboard',
          },
          layout: 'standard',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageStoreHelp" */ './StoreHelp.vue'
          ).catch(handleFailedChunk),
      },
    ],
    // prefetchChunks: ['PageStoreHelp', 'TmlGlobal', 'TmlContainers'],
  },
  {
    path: '/customer-help/',
    name: 'customer-help',
    meta: {
      title: 'Customer Help',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      layout: 'standard',
      showOnSiteMap: true,
      search: {
        show: false,
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageCustomerHelp" */ './CustomerHelp.vue'
      ).catch(handleFailedChunk),
    children: [
      {
        path: 'question/:questionName?/',
        name: 'customer-question-name',
        meta: {
          title: 'Customer Help',
          layout: 'one-pager',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageCustomerHelp" */ './CustomerHelp.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'reason/:reasonName?/',
        name: 'customer-reason-name',
        meta: {
          title: 'Help',
          layout: 'one-pager',
        },
        component: () =>
          import(
            /* webpackChunkName: "PageCustomerHelp" */ './CustomerHelp.vue'
          ).catch(handleFailedChunk),
      },
    ],
    // prefetchChunks: ['PageCustomerHelp', 'TmlGlobal', 'TmlContainers'],
  },
];
