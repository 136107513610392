import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/sitemap/',
    name: 'site-map',
    meta: {
      title: 'Site Map',
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: () =>
      import(/* webpackChunkName: "PageSiteMap" */ './SiteMap.vue').catch(
        handleFailedChunk
      ),
    // prefetchChunks: ['PageSiteMap', 'TmlGlobal'],
  },
];
