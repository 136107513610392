import {formatUrl} from '@teemill/common';
import {http} from '@teemill/common/services';

export default {
  namespaced: true,

  state: {
    code: null,
    items: [],
  },

  getters: {
    getItems(state) {
      return state.items;
    },

    getItem: state => itemId => state.items[itemId],

    getQuantity(state) {
      let count = 0;
      for (let i = 0; i < state.items.length; i++) {
        const item = state.items[i];
        count += item.quantity;
      }

      return count;
    },
  },

  mutations: {
    setDiscountCode(state, code) {
      state.code = code;
    },

    setItems(state, items) {
      if (!items) {
        return;
      }

      state.items = items;
    },

    removeItem(state, key) {
      state.items = state.items.filter(i => i.key !== key);
    },

    updateItem(state, itemData) {
      const item = state.items.find(i => i.key === itemData.key);

      item.quantity = itemData.quantity;
    },

    clear(state) {
      state.items = [];
    },
  },

  actions: {
    async addItem({commit, rootState}, {item, name}) {
      let response = [];

      const context = rootState?.user ? 'dashboard' : 'frontend';

      await http
        .post(formatUrl(`/omnis/v3/${context}/product/addItemToCart/`), {
          item,
          name,
        })
        .success(data => {
          commit('setItems', data.items);
          response = data;
        })
        .oops('Oops... Looks like this product is no longer available');

      return response;
    },

    async removeItem({commit}, {key, name}) {
      let response = [];
      await http
        .post(formatUrl('/omnis/v3/basket/remove/'), {
          key,
          name,
        })
        .success(data => {
          response = data;
          commit('setItems', response.items);
        });

      return response;
    },

    async updateItem({commit}, itemData) {
      let response = [];
      await http
        .post(formatUrl('/omnis/v3/basket/update/'), itemData)
        .success(data => {
          response = data;
          commit('setItems', response.items);
        });

      return response;
    },

    async bulkAddItems({commit, rootState}, {items, name}) {
      let response = [];

      const context = rootState?.user ? 'dashboard' : 'frontend';

      await http
        .post(formatUrl(`/omnis/v3/${context}/product/bulkAddItemsToCart/`), {
          items,
          name,
        })
        .success(data => {
          commit('setItems', data.items);
          response = data;
        })
        .oops('Oops... Looks like this product is no longer available');

      return response;
    },

    async getResource({commit}, {name}) {
      await http
        .get(formatUrl(`/omnis/v3/basket/${name}/minified-list/`))
        .then(({data}) => {
          commit('setItems', data.items);
        });
    },
  },
};
