<script setup lang="ts">
import {ref, computed} from 'vue';
import {captureException} from '@sentry/vue';
import {snackbar} from '@teemill/common/services';
import {Stripe, StripeElements, StripeError} from '@stripe/stripe-js';
import {
  StripePaymentElement,
  StripePaymentElementReadyPayload,
} from '@teemill/modules/stripe';
import {SubscriptionProvider} from '../../providers/subscriptionProvider';
import {subscriptionRoutes} from '../../providers/subscriptionRoutes';
import {
  SubscriptionServerError,
  SubscriptionNotFoundError,
  SubscriptionSetupFailedError,
  SubscriptionValidationError,
} from '../../errors/subscriptionErrors';
import {BillingCycle} from '../../types';

/**
 * The 'update' context is used when the user is updating their subscription payment method.
 * This is used when there is a billing issue with the current payment method. (UpdatePaymentSettingsPopup.vue)
 * or when updating in update popup.
 *
 * The 'subscribe' context is used when the user is subscribing to a new plan.
 */
type PaymentFormContext = 'subscribe' | 'update';

const props = withDefaults(
  defineProps<{
    withTrial?: boolean;
    context: PaymentFormContext;
    origin?: string;
    billingCycle?: BillingCycle;
  }>(),
  {
    withTrial: false,
  }
);

const stripe = ref<Stripe>();
const elements = ref<StripeElements>();

const stripeFormIsReady = computed(() => stripe.value && elements.value);

const paymentType = ref<'setup_intent' | 'payment_intent'>('setup_intent');

const saving = ref(false);

const onCheckout = async () => {
  if (saving.value || !elements.value || !stripeFormIsReady.value) {
    return;
  }

  saving.value = true;

  try {
    const {error: validationError} = await elements.value.submit();

    if (validationError) {
      return snackbar.error(
        validationError?.message ?? 'An unknown error occurred'
      );
    }

    const {clientSecret, type} =
      await SubscriptionProvider.createSubscriptionIntent({
        with_trial: props.withTrial,
        update: props.context === 'update',
        billing_cycle: props.billingCycle,
      });

    paymentType.value = type;

    await confirmSubscriptionIntent(clientSecret);
  } catch (error) {
    if (
      error instanceof SubscriptionServerError ||
      error instanceof SubscriptionNotFoundError ||
      error instanceof SubscriptionSetupFailedError ||
      error instanceof SubscriptionValidationError
    ) {
      snackbar.error(error.message);
    } else {
      snackbar.error('Something went wrong');
    }

    captureException(error);
  } finally {
    saving.value = false;
  }
};

const getReturnUrl = (context: PaymentFormContext) => {
  switch (context) {
    default:
    case 'update':
      return subscriptionRoutes.processPaymentMethodUpdateResponse;
    case 'subscribe':
      return `${subscriptionRoutes.processSubscriptionResponse}/?origin=${props.origin}`;
  }
};

const confirmSubscriptionIntent = async (clientSecret: string) => {
  const {
    error,
  }: {
    error?: StripeError;
  } = await (stripe.value as any)[
    paymentType.value === 'setup_intent' ? 'confirmSetup' : 'confirmPayment'
  ]({
    elements: elements.value,
    clientSecret,
    confirmParams: {
      return_url: getReturnUrl(props.context),
    },
  });

  if (error) {
    return snackbar.error(error?.message ?? 'An unknown error occurred');
  }
};

const onStripeElementsReady = (
  readyPayload: StripePaymentElementReadyPayload
) => {
  stripe.value = readyPayload.stripe;
  elements.value = readyPayload.elements;
};

const buttonText = computed(() => {
  if (props.withTrial) {
    return 'Start free trial';
  }

  switch (props.context) {
    case 'update':
      return 'Confirm';
    default:
    case 'subscribe':
      return 'Upgrade';
  }
});

const stripePaymentMode = computed(() => {
  if (props.withTrial) {
    return 'setup';
  }

  switch (props.context) {
    case 'update':
      return 'setup';
    default:
    case 'subscribe':
      return 'subscription';
  }
});

const amountToCharge = computed(() => {
  if (props.context === 'update' || props.withTrial) {
    return undefined;
  }

  return props.billingCycle === 'yearly' ? 9900 : 1000;
});
</script>

<template>
  <div class="stripe-elements-container">
    <StripePaymentElement
      :billing-cycle="billingCycle"
      :mode="stripePaymentMode"
      :amount="amountToCharge"
      @ready="onStripeElementsReady"
    />

    <tml-button
      class="mt-4"
      href="#"
      :text="buttonText"
      :disabled="!stripeFormIsReady || saving"
      :loading="saving"
      primary
      fill
      force-pro
      @click="onCheckout"
    />
  </div>
</template>