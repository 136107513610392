import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/my-collections/',
    name: 'my-collections',
    meta: {
      title: 'My Collections',
      breadcrumb: {
        type: 'page',
        parent: 'website',
      },
      integration: 'website',
      access: [hasIntegration],
      layout: 'standard',
      search: {
        icon: 'layer-group',
      },
      cloudRedirect: to => {
        return {
          name: 'website.collections.list',
        };
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageMyCollections" */ './MyCollections.vue'
      ).catch(handleFailedChunk),
  },
];
