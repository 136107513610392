import {overlays} from '../../overlays';

export default {
  props: {
    startOpen: Boolean,
    uid: String,
  },

  methods: {
    close() {
      overlays.close(this.uid);
    },
  },
};
