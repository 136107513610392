import {handleFailedChunk} from '@teemill/common/helpers';
import {isLoggedOut} from '../../guards';

export default [
  {
    path: '/forgotten-password/',
    name: 'forgotten-password',
    meta: {
      title: 'Forgotten Password',
      access: [isLoggedOut],
      layout: 'blank',
    },
    component: () =>
      import(
        /* webpackChunkName: "PageForgottenPassword" */ './ForgottenPassword.vue'
      ).catch(handleFailedChunk),
  },
];
