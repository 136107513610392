// eslint-disable-next-line node/no-extraneous-import
import axios, {AxiosRequestConfig} from 'axios';
import Cookies from 'js-cookie';
import {ApiResponse} from '@teemill/common/classes';
import {VueAxios, http} from '@teemill/common/services';
import {useProjectStore} from '@teemill/dashboard/src/stores/project';

const API_TOKEN_COOKIE_NAME = 'tml-api-token';

interface ApiToken {
  id: string;
  project: string;
  token: string;
}

const tmlApiInstance = axios.create({
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-TOKEN',
  baseURL: `${import.meta.env.VITE_API_HOST}/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
}) as VueAxios;

const refreshApiToken = async (): Promise<void> => {
  const token = (await http.post('/dashboard/user/token/legacy'))
    .data as ApiToken;
  token.project = token.project || useProjectStore().active?.id || '';
  Cookies.set(API_TOKEN_COOKIE_NAME, JSON.stringify(token));
};

function stringifyArrays(params: any) {
  Object.keys(params).forEach(key => {
    if (Array.isArray(params[key])) {
      params[key] = params[key].join(',');
    } else if (typeof params[key] === 'object') {
      stringifyArrays(params[key]);
    }
  });
}

function getApiToken(): ApiToken {
  const cookie = Cookies.get(API_TOKEN_COOKIE_NAME);
  if (!cookie) {
    throw new Error('Unable to find API token cookie');
  }
  return JSON.parse(cookie) as ApiToken;
}

tmlApiInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = config.headers || {};
  config.params = config.params || {};
  stringifyArrays(config.params);

  const cookie = Cookies.get(API_TOKEN_COOKIE_NAME);
  if (!cookie) {
    throw new Error('Unable to find API token cookie');
  }

  config.headers['Authorization'] = (JSON.parse(cookie) as ApiToken).token;
  config.params['project'] = useProjectStore().active?.id;

  return config;
});

/**
 * Simple wrapper around the Axios API to provide a consistent interface for making requests.
 *
 * TODO when new Auth is built, the retry logic here should be removed.
 */
export class ApiClient {
  private tmlApiInstance = tmlApiInstance;

  /**
   * This is a temporary solution to allow us to use the public-api from Dashboard before new Auth is built.
   * It attempts to make the request using the API token stored in a cookie. The provided tokens currently expire after 30 seconds.
   * If the request fails due to an expired or missing token, it re-fetches a new token and tries again once.
   */
  private static async retryOnce(
    fn: Function,
    ...args: any[]
  ): Promise<ApiResponse> {
    try {
      if (getApiToken().project !== useProjectStore().active?.id) {
        await refreshApiToken();
      }
    } catch (e) {
      //
    }
    const apiResponseWrapper = (promise: Promise<unknown>): ApiResponse =>
      new ApiResponse((resolve, reject) => {
        promise.then(resolve).catch(reject);
      });

    try {
      return await apiResponseWrapper(fn(...args));
    } catch (e) {
      // if we get an Axios error without a 401 status, don't retry, instead just throw the error
      if (axios.isAxiosError(e) && e.response?.status !== 401) {
        throw e;
      }
      await refreshApiToken();

      return await apiResponseWrapper(fn(...args));
    }
  }

  public async get(
    ...args: Parameters<typeof tmlApiInstance.get>
  ): Promise<ApiResponse> {
    return ApiClient.retryOnce(this.tmlApiInstance.get, ...args);
  }

  public async post(
    ...args: Parameters<typeof tmlApiInstance.post>
  ): Promise<ApiResponse> {
    return ApiClient.retryOnce(this.tmlApiInstance.post, ...args);
  }

  public async put(
    ...args: Parameters<typeof tmlApiInstance.put>
  ): Promise<ApiResponse> {
    return ApiClient.retryOnce(this.tmlApiInstance.put, ...args);
  }

  public async patch(
    ...args: Parameters<typeof tmlApiInstance.patch>
  ): Promise<ApiResponse> {
    return ApiClient.retryOnce(this.tmlApiInstance.patch, ...args);
  }

  public async delete(
    ...args: Parameters<typeof tmlApiInstance.delete>
  ): Promise<ApiResponse> {
    return ApiClient.retryOnce(this.tmlApiInstance.delete, ...args);
  }
}

export const tmlApi = new ApiClient();
