import {createApp, configureCompat, markRaw} from 'vue';
import {createWebHistory} from 'vue-router';
import {createHead} from '@vueuse/head';
import {createPinia} from 'pinia';
import {
  http,
  sentry,
  fontawesome,
  events,
  dialogs,
  snackbar,
  datadog,
  gtm,
  permissions,
} from '@teemill/common/services';
import {breadcrumbs, tracker} from '@teemill/common/plugins';
import {overlays} from '@teemill/modules/overlays';
import {AccessStatement} from '@teemill/common/classes';
import {TeemillComponents} from '@teemill/components/plugin';
import PageFramework from '@teemill/modules/page-framework';
import ContentWallet from '@teemill/modules/content-wallet';
import TeemailPlugin from './pages/teemail/plugins/index';
import Drafts from './pages/teemail/drafts/index';
import Campaigns from './pages/teemail/campaigns/index';
import {project} from './plugins/project';
import {Audience, Configuration} from '@teemill/modules/teemail';

import {store, createRouter} from './services';

import App from './App.vue';
import './index.css';
import {isFeatureEnabled, setup as setupFeatures} from './features';

import 'iframe-resizer/js/iframeResizer.contentWindow';

const router = createRouter(createWebHistory());

const pinia = createPinia();
pinia.use(({store}) => {
  store.router = markRaw(router);
});

/**
 * Register Deprecated Globals
 */
window.$axios = http;
window.axios = http;
window.$store = store;
window.$router = router;
window.snackbar = snackbar;
window.$eventBus = events;
window.dataLayer = window.dataLayer || [];

window.gtag = function () {
  // eslint-disable-next-line
  window.dataLayer?.push(arguments);
};

const surpressedWarnings: Parameters<typeof configureCompat>[0] = {
  GLOBAL_MOUNT: 'suppress-warning',
  GLOBAL_EXTEND: 'suppress-warning',
  GLOBAL_PROTOTYPE: 'suppress-warning',
  GLOBAL_SET: 'suppress-warning',
  GLOBAL_DELETE: 'suppress-warning',
  GLOBAL_OBSERVABLE: 'suppress-warning',
  CONFIG_KEY_CODES: 'suppress-warning',
  CONFIG_WHITESPACE: 'suppress-warning',
  INSTANCE_SET: 'suppress-warning',
  INSTANCE_DELETE: 'suppress-warning',
  INSTANCE_EVENT_EMITTER: 'suppress-warning',
  INSTANCE_EVENT_HOOKS: 'suppress-warning',
  INSTANCE_CHILDREN: 'suppress-warning',
  INSTANCE_LISTENERS: 'suppress-warning',
  INSTANCE_SCOPED_SLOTS: 'suppress-warning',
  INSTANCE_ATTRS_CLASS_STYLE: 'suppress-warning',
  OPTIONS_DATA_FN: 'suppress-warning',
  OPTIONS_DATA_MERGE: 'suppress-warning',
  OPTIONS_BEFORE_DESTROY: 'suppress-warning',
  OPTIONS_DESTROYED: 'suppress-warning',
  WATCH_ARRAY: 'suppress-warning',
  V_ON_KEYCODE_MODIFIER: 'suppress-warning',
  CUSTOM_DIR: 'suppress-warning',
  ATTR_FALSE_VALUE: 'suppress-warning',
  ATTR_ENUMERATED_COERCION: 'suppress-warning',
  TRANSITION_GROUP_ROOT: 'suppress-warning',
  COMPONENT_ASYNC: 'suppress-warning',
  COMPONENT_FUNCTIONAL: 'suppress-warning',
  COMPONENT_V_MODEL: 'suppress-warning',
  RENDER_FUNCTION: 'suppress-warning',
  FILTERS: 'suppress-warning',
};

configureCompat({
  MODE: 2,
  ...surpressedWarnings,

  V_ON_KEYCODE_MODIFIER: false, // affects TmlInput
  RENDER_FUNCTION: false, // affects slots, e.g. use cases of TmlStages and TmlNewSelect
  COMPONENT_V_MODEL: true,
  INSTANCE_LISTENERS: true,
  INSTANCE_EVENT_EMITTER: true,
});

AccessStatement.store = store;
AccessStatement.router = router;

setupFeatures();

const app = createApp(App)
  .use(http)
  .use(store)
  .use(router)
  .use(pinia)
  .use(permissions, {store})
  .use(overlays)
  .use(breadcrumbs, {store, router})
  .use(tracker)

  .use({
    install(app) {
      app.config.globalProperties.$app = app;
      app.config.globalProperties.axios = http;
      app.config.globalProperties.$store = store;
      app.config.globalProperties.$eventBus = events;
    },
  })
  .use(dialogs, store)
  .use(ContentWallet)
  .use(TeemailPlugin, {
    router,
    routes: [Drafts, Campaigns, Audience, Configuration],
  })
  .use(createHead())
  .use(project, {store, router, http})
  .use({
    install(app) {
      let accessAttempts = 0;

      router.beforeEach((to, from, next) => {
        to.matched.forEach(route => {
          if (!route.meta.access) {
            return;
          }

          const result = AccessStatement.run(
            route.meta.access as AccessStatement[],
            to
          );

          if (!result.passed) {
            accessAttempts += 1;

            if (accessAttempts > 100) {
              accessAttempts = 0;

              // eslint-disable-next-line
              console.error('Possible access loop detected');

              next('/404/');
            } else if (result.statement) {
              next(result.statement.redirect(to));
            }
            return;
          }
          accessAttempts = 0;
        });

        // Track conversion for subscribe (needed here because of redirect)
        if (
          to.name === 'dashboard' &&
          (to.query?.subscribed === 'monthly' ||
            to.query?.subscribed === 'yearly')
        ) {
          tracker.conversion(
            'subscribe',
            to.query?.subscribed === 'monthly' ? 10 : 99,
            []
          );
          delete to.query.subscribed;
          return next({path: to.path, query: to.query});
        }

        next();
      });
    },
  })
  .mixin({
    // data() {
    //   return {
    //     mobile: true,
    //   };
    // },
    methods: {
      // !deprecated
      hasPermission() {
        // return atlas permission checks as true on Teemill Dashboard as atlas permissions do no apply there
        return true;
      },
      // !deprecated
      hasDashboardPermission(permission: string) {
        if (!permission) {
          return true;
        }

        return this.$store.getters['auth/hasDashboardPermissions'](permission);
      },
    },
  });

if (isFeatureEnabled('datadog')) {
  app.use(datadog, {
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    version: import.meta.env.VITE_APP_VERSION,
    service: import.meta.env.VITE_APP_NAME,
    sampleRate: 0,
  });
}

if (isFeatureEnabled('fontawesome')) {
  app.use(fontawesome);
}

if (isFeatureEnabled('pages')) {
  app.use(PageFramework);
}

if (isFeatureEnabled('components')) {
  app.use(TeemillComponents);
}

if (isFeatureEnabled('sentry')) {
  app.use(sentry, {
    router,
    version: import.meta.env.VITE_APP_VERSION,
  });
}

if (isFeatureEnabled('gtm')) {
  window.gtag('consent', 'default', {
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
    wait_for_update: 500,
  });

  window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});

  app.use(gtm, {
    id: import.meta.env.VITE_GTM_ID,
    debug: import.meta.env.VITE_GTM_DEBUG === 'true',
    router,
    trackViewEventProperty: 'TmlPageViewEvent',
  });
}

app.mount('#app');
