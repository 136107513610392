import {
  faShopify,
  faWix,
  faAmazon,
  faEtsy,
  faWordpress,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCartShoppingFast,
  faCode,
  faIndustryWindows,
  faMusic,
  faPlug,
  faQuestion,
} from '@fortawesome/pro-light-svg-icons';
import {formatUrl} from '@teemill/common/helpers';
import {http} from '@teemill/common/services';
import {useProjectStore} from '../stores/project';

export interface ProjectLike {
  id: string;
  name: string;
  logo: {
    url: string;
    backgroundColor: string;
  };
  manager: {
    id: string;
    logo: string | null;
  } | null;
  platform: {
    logos: {src: string; meta: {device?: string; theme?: string} | null}[];
  } | null;
  isPro: boolean;
  isTurbo: boolean;
  integrations: {
    type: string;
    config?: object;
  }[];
  badges: {
    name: string;
    code: string;
    groups: {name: string; code: string}[];
    unlocked: boolean;
  }[];
  betas: string[];
  managed: boolean;
  demographic: {
    name: string;
    code: string;
  };
  force2fa: boolean;
  hash: string;
  user: {
    role: object;
    permissions: string[];
  };
  currency: string;
}

export class Project implements ProjectLike {
  id: string;
  name: string;
  logo: {
    url: string;
    backgroundColor: string;
  };
  manager: {id: string; logo: string | null} | null;
  platform: {
    domain?: string;
    logos: {src: string; meta: {device?: string; theme?: string} | null}[];
  } | null;
  isPro: boolean;
  isTurbo: boolean;
  integrations: {
    type: string;
    config?: object;
  }[];
  badges: {
    name: string;
    code: string;
    groups: {name: string; code: string}[];
    unlocked: boolean;
  }[];
  betas: string[];
  managed: boolean;
  demographic: {
    name: string;
    code: string;
  };
  force2fa: boolean;
  hash: string;
  user: {
    role: object;
    permissions: string[];
  };
  currency: string;

  constructor({
    id,
    name,
    logo,
    manager,
    platform = null,
    isPro,
    isTurbo,
    integrations = [],
    badges = [],
    betas = [],
    managed,
    demographic,
    force2fa,
    hash,
    user,
    currency,
  }: ProjectLike) {
    this.id = id;
    this.name = name;
    this.logo = logo;
    this.manager = manager;
    this.platform = platform;
    this.isPro = isPro;
    this.isTurbo = isTurbo;
    this.integrations = integrations;
    this.badges = badges;
    this.betas = betas;
    this.managed = managed;
    this.demographic = demographic;
    this.force2fa = force2fa;
    this.hash = hash;
    this.user = user;
    this.currency = currency;
  }

  get integrationType() {
    if (this.hasIntegration('website')) {
      if (this.hasIntegration('api')) {
        return 'API';
      } else {
        return 'Teemill Store';
      }
    } else if (this.hasIntegration('shopify')) {
      return 'Shopify';
    } else if (this.hasIntegration('wix')) {
      return 'Wix';
    } else if (this.hasIntegration('ecwid')) {
      return 'Ecwid';
    } else if (this.hasIntegration('ekm')) {
      return 'EKM';
    } else if (this.hasIntegration('amazon')) {
      return 'Amazon';
    } else if (this.hasIntegration('etsy')) {
      return 'Etsy';
    } else if (this.hasIntegration('woocommerce')) {
      return 'WooCommerce';
    } else if (this.hasIntegration('ochre')) {
      return 'Ochre';
    } else if (this.hasIntegration('tiktokShop')) {
      return 'TikTok Shop';
    } else if (this.hasIntegration('fulfillments')) {
      return 'Factory';
    } else {
      return 'Not Connected';
    }
  }

  get icon() {
    switch (this.integrationType) {
      case 'Teemill Store':
        return faCartShoppingFast;
      case 'API':
        return faCode;
      case 'Shopify':
        return faShopify;
      case 'Wix':
        return faWix;
      case 'Amazon':
        return faAmazon;
      case 'Etsy':
        return faEtsy;
      case 'WooCommerce':
        return faWordpress;
      case 'Ochre':
        return faMusic;
      case 'TikTok Shop':
        return faTiktok;
      case 'Ecwid':
      case 'EKM':
        return faPlug;
      case 'Factory':
        return faIndustryWindows;
      default:
        return faQuestion;
    }
  }

  get hubRouteName() {
    switch (this.integrationType) {
      case 'Factory':
        return 'factory';
      default:
        return 'dashboard';
    }
  }

  get sortableId() {
    const match = this.id.match(/-(\d+)$/);
    return match ? parseInt(match[1]) : 0;
  }

  static createOld(
    name: string,
    demographic: {
      name: string;
      code: string;
    },
    integrationType: string,
    promoUrl: string | null = null,
    affiliate: string | null = null
  ) {
    return http
      .post(formatUrl('/omnis/v3/shop/manufacturer/create/'), {
        name,
        demographic,
        integrationType,
        promoUrl,
        affiliate,
      })
      .success((data: object) => {
        window.$store.dispatch('insertInitialState', data);
      }, false);
  }
  /**
   * @deprecated
   */
  static oldUpdate(id: string, properties: object) {
    const projectStore = useProjectStore();

    return http
      .post(formatUrl('/omnis/v3/shop/manufacturer/update/'), {
        id,
        ...properties,
      })
      .success(
        (data: ProjectLike) => projectStore.update(new Project(data)),
        false
      );
  }

  static create(name: string) {
    return http
      .post(formatUrl('/omnis/v3/dashboard/projects/'), {
        name,
      })
      .success(
        (data: ProjectLike) => useProjectStore().update(new Project(data)),
        false
      );
  }

  public fresh() {
    const projectStore = useProjectStore();

    return http
      .get(formatUrl(`/omnis/v3/dashboard/projects/${this.id}`))
      .success((data: ProjectLike) => projectStore.update(new Project(data)))
      .output();
  }

  public hasBadge(code: string[] | string) {
    return Array.isArray(code)
      ? code.every(badgeCode =>
          this.badges.find(badge => badge.unlocked && badge.code === badgeCode)
        )
      : this.badges.find(badge => badge.unlocked && badge.code === code);
  }

  public hasIntegration(type: string[] | string): boolean {
    return Array.isArray(type)
      ? type.some(integration =>
          this.integrations.find(i => i.type === integration)
        )
      : !!this.integrations.find(i => i.type === type);
  }

  public isInBeta(code: string): boolean {
    return this.betas.includes(code);
  }

  public isNotTeemill(): boolean {
    return !!this.integrations.length && !this.hasIntegration('website');
  }
}
