import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/plugins/:plugin/:tab?/',
    name: 'plugin',
    meta: {
      title: 'Plugin',
      breadcrumb: {
        type: 'plugin',
        parent: 'dashboard',
      },
      integration: 'website',
      access: [hasIntegration],
      layout: 'standard',
      cloudRedirect: to => {
        return {
          name: 'website.plugins.view',
          params: {
            id: to.params.plugin,
          },
        };
      },
    },
    component: () =>
      import(/* webpackChunkName: "PagePlugins" */ './Plugin.vue').catch(
        handleFailedChunk
      ),
  },
];
