import {LayoutSections} from '@teemill/modules/app';
import {viewportSize} from '@teemill/common/helpers';
import {computedEager, useWindowScroll} from '@vueuse/core';

import {store} from '../services';

const {y: scrollY} = useWindowScroll();

const isLoggedIn = () => store?.state.user;

export const standardConfig: LayoutSections = {
  announcement: {show: true},
  header: {
    show: true,
    fixed: true,
    overlay: false,
    width: () => (isLoggedIn() ? '100%' : 'container'),
    height: computedEager(() => {
      if (!isLoggedIn()) {
        if (viewportSize.isSmaller('md')) {
          return '3.5rem';
        }

        if (scrollY.value === 0) {
          return '5rem';
        }
      }

      return '4rem';
    }),
    logo: true,
    logoMaxWidth: '220px',
    centerLogo: () => (isLoggedIn() ? false : viewportSize.isSmaller('md')),
    content: true,
  },
  sidebar: {
    show: () => (isLoggedIn() ? true : viewportSize.isSmaller('md')),
    fixed: () => (isLoggedIn() ? true : false),
    full: false,
    width: '220px',
  },
  content: {
    show: true,
    width: () => (isLoggedIn() ? '100%' : 'container'),
  },
  footer: {
    show: () => (isLoggedIn() ? false : true),
  },
  subFooter: {show: true},
  navBar: {
    show: () => (isLoggedIn() ? viewportSize.isSmaller('md') : false),
    height: '3rem',
  },
};

// export const standardConfig: LayoutSections = store?.state.user
//   ? loggedInConfig
//   : onePagerConfig;
