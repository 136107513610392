import {BaseClass} from '../../classes/baseClass';

export default class CustomDomain extends BaseClass {
  constructor({domain, isSetUp, disabled} = {}) {
    super();

    this.domain = domain;
    this.isSetUp = !!isSetUp;
    this.disabled = disabled;
  }
}
