import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess, hasStore} from '@teemill/common/guards';
import {RouteLocationNormalized} from 'vue-router';

export default {
  path: 'configuration',
  meta: {
    breadcrumb: {
      type: 'page',
      parent: 'teemail.index',
    },
    title: 'Configuration',
    access: [hasDashboardAccess, hasStore],
    layout: 'standard',
  },
  component: () =>
    import(
      /* webpackChunkName: "PageTeemail" */ '../components/routes/configuration/Index.vue'
    ).catch(handleFailedChunk),
  children: [
    {
      path: '',
      name: 'teemail.configurations.list',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'teemail.index',
        },
        title: 'Configurations',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
      },
      component: () =>
        import(
          /* webpackChunkName: "PageTeemail" */ '../components/routes/configuration/List.vue'
        ).catch(handleFailedChunk),
    },
    {
      path: ':configuration',
      name: 'teemail.configurations.edit',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'teemail.configurations.list',
        },
        title: 'Edit configuration',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
        cloudRedirect: (to: RouteLocationNormalized) => ({
          name: 'website.teemail.settings.edit',
          params: {
            settings: to.params.configuration,
          },
        }),
      },
      component: () =>
        import(
          /* webpackChunkName: "PageTeemail" */ '../components/routes/configuration/Edit.vue'
        ).catch(handleFailedChunk),
    },
  ],
};
