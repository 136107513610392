import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration, hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/logo/',
    name: 'logo-editor',
    meta: {
      title: 'Logo',
      breadcrumb: {
        type: 'page',
        parent: 'website',
      },
      integration: 'website',
      dashboardPermissions: ['edit-webpage'],
      access: [hasIntegration, hasDashboardPermissions],
      cloudRedirect: () => ({
        name: 'website.logo.edit',
      }),
      layout: 'standard',
      search: {
        icon: 'image',
      },
    },
    component: () =>
      import(/* webpackChunkName: "PageLogoEditor" */ './LogoEditor.vue').catch(
        handleFailedChunk
      ),
  },
];
