import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/account-settings/', // ! Legacy
    redirect: '/settings/',
    meta: {
      search: {
        show: false,
      },
      allowPwaReload: true,
    },
  },
  {
    path: '/settings/',
    name: 'settings',
    meta: {
      title: 'Settings',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      access: hasDashboardAccess,
      layout: 'standard',
      search: {
        icon: 'cog',
      },
      allowPwaReload: true,
    },
    alias: ['/payment-settings/'],
    component: () =>
      import(
        /* webpackChunkName: "PageAccountSettings" */ './AccountSettingsPage.vue'
      ).catch(handleFailedChunk),
  },
];
