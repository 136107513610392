import {handleFailedChunk} from '@teemill/common/helpers';
import {
  hasIntegration,
  isPro,
  hasDashboardPermissions,
  isNotTurbo,
} from '../../guards';

export default [
  {
    path: '/bundle-maker/:productUrlName?/',
    alias: ['/edit-bundle/:productUrlName?/'],
    name: 'bundle-maker',
    meta: {
      title: 'Bundle Maker',
      breadcrumb: {
        type: 'page',
        parent: 'My Products',
      },
      integration: 'website',
      dashboardPermissions: ['edit-products'],
      access: [hasIntegration, isPro, hasDashboardPermissions, isNotTurbo],
      layout: 'standard',
      search: {
        url: () => '/bundle-maker/',
        icon: 'layer-plus',
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageBundleMaker" */ './BundleMaker.vue'
      ).catch(handleFailedChunk),
  },
];
