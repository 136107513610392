import {formatUrl} from '../../helpers';
import {Customer} from '@teemill/common/models';

export default {
  namespaced: true,

  state: () => ({
    unread: 0,
    count: 0,
  }),

  mutations: {
    setUnread(state, unread) {
      state.unread = unread;
    },

    setCount(state, count) {
      state.count = count;
    },
  },

  actions: {
    meta({commit}) {
      $axios
        .get(formatUrl('/omnis/v3/notifications/dashboard/meta/'))
        .success(({count, unread}) => {
          commit('setUnread', unread);
          commit('setCount', count);
        })
        .handle(401, () => {
          Customer.logout();
        });
    },

    markAllAsRead({commit}) {
      commit('setUnread', 0);

      $axios
        .post(formatUrl('/omnis/v3/notifications/dashboard/mark-all-as-read/'))
        .handle(401, () => {
          Customer.logout();
        });
    },
  },
};
