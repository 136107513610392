import {handleFailedChunk} from '@teemill/common/helpers';

import {
  hasDashboardPermissions,
  hasIntegration,
  isInternalUser,
} from '../../guards/';

export default [
  {
    path: '/blogs/:pathMatch(.*)',
    redirect: route => route.path.replace('/blogs/', '/my-blogs/'),
    meta: {
      search: {
        show: false,
      },
    },
  },
  {
    path: '/my-blogs/',
    meta: {
      title: 'Blogs',
      integration: 'website',
      access: [hasIntegration],
      layout: 'standard',
      search: {
        icon: 'bullhorn',
      },
    },
    component: () => import('./MyBlogsIndex.vue').catch(handleFailedChunk),
    children: [
      {
        path: '',
        name: 'blog.list',
        meta: {
          title: 'My blogs',
          breadcrumb: {
            type: 'page',
            parent: 'website',
          },
          layout: 'standard',
          cloudRedirect: to => ({
            name: 'website.blog.list',
          }),
        },
        component: () =>
          import('./MyBlogsListPage.vue').catch(handleFailedChunk),
      },
      {
        path: 'edit/:post?/',
        name: 'blog.edit',
        meta: {
          title: 'Edit post',
          dashboardPermissions: ['edit-blogs'],
          access: [hasDashboardPermissions],
          breadcrumb: {
            type: 'page',
            parent: 'blog.list',
            position: 'custom',
          },
          layout: 'tool',
          cloudRedirect: to => {
            return {
              name: 'website.blog.edit',
              params: {
                slug: to.params.post,
              },
            };
          },
        },
        component: () =>
          import('./MyBlogsEditPage.vue').catch(handleFailedChunk),
      },
      {
        path: 'create/',
        name: 'blog.create',
        meta: {
          title: 'Create post',
          dashboardPermissions: ['edit-blogs'],
          access: [hasDashboardPermissions],
          breadcrumb: {
            type: 'page',
            parent: 'blog.list',
            position: 'custom',
          },
          layout: 'tool',
          cloudRedirect: to => ({
            name: 'website.blog.create',
          }),
        },
        component: () =>
          import('./MyBlogsCreatePage.vue').catch(handleFailedChunk),
      },
      {
        path: 'series/',
        name: 'blog.series',
        meta: {
          title: 'Blog series',
          access: [isInternalUser],
          layout: 'standard',
          search: {
            show: false,
          },
        },
        component: () =>
          import('./series/MyBlogsSeriesIndex.vue').catch(handleFailedChunk),
        children: [
          {
            path: '',
            name: 'blog.series.list',
            meta: {
              title: 'My series',
              layout: 'standard',
              breadcrumb: {
                type: 'page',
                parent: 'blog.list',
              },
            },
            component: () =>
              import('./series/MyBlogsSeriesListPage.vue').catch(
                handleFailedChunk
              ),
          },
          {
            path: 'edit/:urlTitle',
            name: 'blog.series.edit',
            meta: {
              title: 'Edit series',
              dashboardPermissions: ['edit-blogs'],
              access: [hasDashboardPermissions],
              breadcrumb: {
                type: 'page',
                parent: 'blog.series.list',
                position: 'custom',
              },
              layout: 'tool',
            },
            props: true,
            component: () =>
              import('./series/MyBlogsSeriesEditPage.vue').catch(
                handleFailedChunk
              ),
          },
          {
            path: 'create',
            name: 'blog.series.create',
            meta: {
              title: 'Create series',
              dashboardPermissions: ['edit-blogs'],
              access: [hasDashboardPermissions],
              breadcrumb: {
                type: 'page',
                parent: 'blog.series.list',
                position: 'custom',
              },
              layout: 'tool',
            },
            component: () =>
              import('./series/MyBlogsSeriesCreatePage.vue').catch(
                handleFailedChunk
              ),
          },
        ],
      },
    ],
  },
];
