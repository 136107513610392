import {isInternalUser} from '../../guards';

export default [
  {
    path: '/slogan-builder/',
    name: 'SloganBuilder',
    meta: {
      title: 'Slogan Builder',
      layout: 'tool',
      access: [isInternalUser],
      showOnSiteMap: false,
    },
    component: () =>
      import(/* webpackChunkName: "PageSloganBuilder" */ './SloganBuilder.vue'),
  },
];
