import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration, hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/theme/',
    alias: '/customise-store/',
    redirect: '/theme/edit/',
    name: 'theme',
    meta: {
      title: 'Theme',
      breadcrumb: {
        type: 'page',
        parent: 'website',
      },
      integration: 'website',
      dashboardPermissions: ['edit-webpage'],
      access: [hasIntegration, hasDashboardPermissions],
      layout: 'standard',
      search: {
        icon: 'paint-roller',
      },
    },
    component: () => import('./ThemeEditorIndex.vue').catch(handleFailedChunk),
    children: [
      {
        path: 'select/',
        name: 'theme-select',
        meta: {
          title: 'Change Theme',
          breadcrumb: {
            type: 'page',
            parent: 'website',
          },
          layout: 'standard',
          cloudRedirect: () => {
            return {
              name: 'website.themes.list',
            };
          },
        },
        component: () =>
          import('./ThemeSelectorPage.vue').catch(handleFailedChunk),
      },
      {
        path: 'edit/',
        name: 'theme-edit',
        meta: {
          title: 'Edit Theme',
          breadcrumb: {
            type: 'page',
            parent: 'website',
          },
          layout: 'standard',
          search: {
            show: false,
          },
          cloudRedirect: () => {
            return {
              name: 'website.themes.edit',
            };
          },
        },
        component: () =>
          import('./ThemeEditorPage.vue').catch(handleFailedChunk),
      },
    ],
  },
];
