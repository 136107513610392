import {useProjectStore} from '../../stores/project';
import {apiHost} from '@teemill/common/helpers';

let router = null;

export const project = {
  install(app, {store, router: appRouter, http}) {
    const projectStore = useProjectStore();
    window.$project = projectStore;
    app.config.globalProperties.$project = projectStore;

    router = appRouter;
    appRouter.beforeEach((to, from, next) => {
      // Do nothing if not logged in
      if (projectStore.projects.length === 0) {
        next();
        return;
      }

      let project = to.query.project;

      // Some routes don't require an active project. This removes the project query from the url if necessary
      if (to.meta.requireProject === false) {
        if (project) {
          const query = to.query;

          delete query.project;
          next({
            ...to,
            query: query,
          });
        } else {
          next();
        }

        return;
      }

      if (!projectStore.find(project)) {
        project = from.query.project;
      }

      if (!projectStore.find(project)) {
        project = window.localStorage.getItem('project');
      }

      if (!projectStore.find(project)) {
        project = projectStore.projects.filter(p => !p.isTurbo).at(0)?.id;
      }

      if (!projectStore.find(project)) {
        project = projectStore.projects.at(0)?.id;
      }

      window.localStorage.setItem('project', project);

      if (from.query.project !== project) {
        store.dispatch(
          'store/active',
          store.getters['store/findByManufacturer'](project)
        );
      }

      if (to.query.project !== project) {
        next({
          ...to,
          query: {
            ...to.query,
            project: project,
          },
        });
        return;
      }

      next();
    });

    http.interceptors.request.use(config => {
      config.params = config.params || {};

      // if using default baseURL or explicitly using core-api
      if (config.url.startsWith('/') || config.url.startsWith(apiHost())) {
        config.params['project'] = projectStore.active?.id;
      }

      return config;
    });
  },
};

export const setActiveProject = project => {
  router.replace({query: {project}});
};
