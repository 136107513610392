import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/checkout/',
    name: 'Checkout',
    meta: {
      title: 'Checkout',
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: () =>
      import('./DashboardCheckoutPage.vue').catch(handleFailedChunk),
  },
];
