import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/design-your-own-t-shirt/',
    name: 'design-your-own-t-shirt',
    meta: {
      title: 'Studio',
      layout: 'tool',
    },
    component: () =>
      import('./DesignYourOwnTShirt.vue').catch(handleFailedChunk),
  },
];
