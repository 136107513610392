import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/communication-preferences/',
    name: 'communication-preferences',
    meta: {
      title: 'Communication Preferences',
      access: hasDashboardAccess,
      layout: 'standard',
      breadcrumb: {
        type: 'page',
        parent: 'settings',
      },
      search: {
        show: false,
      },
    },
    component: () =>
      import('./CommunicationPreferences.vue').catch(handleFailedChunk),
  },
  {
    path: '/communication-preferences/update/',
    name: 'communication-preferences.update',
    meta: {
      title: 'Update',
      layout: 'blank',
      search: {
        show: false,
      },
    },
    component: () =>
      import('./CommunicationPreferencesUpdatePage.vue').catch(
        handleFailedChunk
      ),
  },
];
