import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/create-a-product/:productUrlName?/',
    alias: ['/studio/:productUrlName?/', '/edit-product/:productUrlName?/'],
    name: 'studio',
    meta: {
      title: 'Studio',
      access: hasDashboardAccess,
      layout: 'tool',
      search: {
        url: () => '/create-a-product/',
      },
      cloudRedirect: to => {
        return {
          name: 'catalog.legacy-studio',
          params: {
            id: to.params.productUrlName,
          },
        };
      },
    },
    component: () =>
      import('./DashboardStudioPage.vue').catch(handleFailedChunk),
  },
];
