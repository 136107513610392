import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess, hasStore} from '@teemill/common/guards';

export default {
  path: '/teemail',
  name: 'teemail.base',
  meta: {
    layout: 'standard',
    title: 'Teemail',
    search: {
      icon: 'envelope',
    },
  },
  component: () => import('./TeemailIndex.vue').catch(handleFailedChunk),
  children: [
    {
      path: '',
      name: 'teemail.index',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'marketing',
        },
        title: 'Teemail',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
      },
      component: () =>
        import(
          /* webpackChunkName: "PageTeemail" */ './LandingPageContainer.vue'
        ).catch(handleFailedChunk),
    },
  ],
};
