import {handleFailedChunk} from '@teemill/common/helpers';
import orders from './orders';

export default [
  {
    path: '/ecom',
    name: 'ecom.index',
    meta: {
      title: 'Ecom',
      search: {
        show: false,
      },
    },
    component: () => import('./EcomIndex.vue').catch(handleFailedChunk),
    children: [
      {
        path: '',
        name: 'ecom',
        meta: {
          title: 'Ecom',
          layout: 'standard',
          search: {
            show: false,
          },
          breadcrumb: {
            type: 'page',
          },
        },
        component: () => import('./EcomPage.vue').catch(handleFailedChunk),
      },
      ...orders,
    ],
  },
  {
    path: '/orders/',
    redirect: {name: 'ecom.orders'},
  },
];
