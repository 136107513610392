import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/shopify/',
    redirect: '/integrations/',
  },
  {
    path: '/integrations/',
    name: 'integrations',
    children: [
      {
        path: 'sales-activity',
        name: 'integrations.sales-activity',
        meta: {
          title: 'Sales Activity',
          dashboardPermissions: ['view-royalties'],
          access: [hasDashboardPermissions],
          layout: 'standard',
        },
        component: () =>
          import('./sales-activity/ShopifySalesActivityPage.vue').catch(
            handleFailedChunk
          ),
      },
      {
        path: 'pricing',
        name: 'integrations.pricing',
        meta: {
          title: 'Pricing',
          layout: 'standard',
          search: {
            show: false,
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "PageShopifyPriceBreakdown" */ './price-breakdown/PriceBreakdown.vue'
          ).catch(handleFailedChunk),
      },
    ],
  },
];
