import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess, isNotTurbo} from '../../guards';

export default [
  {
    path: '/wholesale-shop/',
    name: 'Samples Shop',
    meta: {
      title: 'Samples Shop',
      access: [hasDashboardAccess, isNotTurbo],
      layout: 'standard',
      search: {
        icon: 'shopping-bag',
        url: () => '/wholesale-shop/',
      },
      allowPwaReload: true,
    },
    component: () => import('./SamplesShop.vue').catch(handleFailedChunk),
  },
];
