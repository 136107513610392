import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/legal/',
    name: 'legal',
    meta: {
      title: 'Legal',
      layout: 'standard',
    },
    component: () =>
      import(/* webpackChunkName: "PageLegal" */ './Legal.vue').catch(
        handleFailedChunk
      ),
  },
];
