import {formatUrl} from '@teemill/common/helpers';

export const subscriptionRoutes = {
  createSubscriptionIntent: formatUrl(
    '/omnis/v3/saas/stripe/subscription/intent'
  ),
  processSubscriptionResponse: formatUrl(
    '/omnis/v3/saas/stripe/subscription/subscribe'
  ),
  processUpdateResponse: formatUrl('/omnis/v3/saas/stripe/subscription/update'),
  processPaymentMethodUpdateResponse: formatUrl(
    '/omnis/v3/saas/stripe/subscription/update/payment-method'
  ),
  getCurrentSubscription: formatUrl('/omnis/v3/saas/stripe/subscription'),
  updateBillingCycle: formatUrl(
    '/omnis/v3/saas/stripe/subscription/billing-cycle'
  ),
  getSubscription: formatUrl('/omnis/v3/saas/stripe/subscription'),
  canReceiveFreeTrial: formatUrl(
    '/omnis/v3/saas/stripe/subscription/can-receive-free-trial'
  ),
};
