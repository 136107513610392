import {handleFailedChunk} from '@teemill/common/helpers';

export default [
  {
    path: '/invitation-expired/',
    name: 'Expired',
    meta: {
      title: 'Expired',
      layout: 'blank',
      search: {
        show: false,
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageInvitationExpired" */ './InvitationExpired.vue'
      ).catch(handleFailedChunk),
    // prefetchChunks: ['PageInvitationExpired', 'TmlGlobal'],
  },
  {
    path: '/invitation-reported/',
    name: 'Reported',
    meta: {
      title: 'Reported',
      layout: 'blank',
      search: {
        show: false,
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageInvitationReported" */ './InvitationReported.vue'
      ).catch(handleFailedChunk),
    // prefetchChunks: ['PageInvitationReported', 'TmlGlobal'],
  },
];
