<script setup lang="ts">
import {computed, onMounted, onUnmounted} from 'vue';
import {overlays} from '@teemill/modules/overlays';
import {events} from '@teemill/common/services';
import {splitTests} from '@teemill/common/classes';
import upgradeToProImage from '../assets/img/upgrade-to-pro-min.jpeg';
import ProPaymentForm from '../components/payment/ProPaymentForm.vue';
import ProBillingCycleSelect from '../components/payment/ProBillingCycleSelect.vue';
import {useSaas} from '../useSaas';

const props = withDefaults(
  defineProps<{
    name?: string;
    isPro: boolean;
  }>(),
  {
    name: 'pro-subscribe-popup',
  }
);

const {upgrade, subscribe} = useSaas();

const billingCycle = computed(
  () => upgrade.information.billingCycle || 'monthly'
);

const showFreeTrial = computed(
  () =>
    !props.isPro &&
    upgrade.canReceiveFreeTrial.value &&
    splitTests.isActiveVariation('free_trial_pro', 'with')
);

const openProSubscribePopup = () => {
  overlays.open('pro-subscribe-popup');
};

const openProSubscribePopupFromActionLink = () => {
  subscribe.openPopup({
    plan: 'pro',
    origin: 'action-link',
  });
};

onMounted(() => {
  events.on('anchor-upgrade-to-pro', openProSubscribePopupFromActionLink);
  events.on('open-pro-subscribe-popup', openProSubscribePopup);
});

onUnmounted(() => {
  events.off('anchor-upgrade-to-pro', openProSubscribePopupFromActionLink);
  events.off('open-pro-subscribe-popup', openProSubscribePopup);
});
</script>

<template>
  <div>
    <tml-new-popup
      v-if="!isPro"
      :name="name"
      title="Let's do this"
      layout="standard"
      size="normal"
      height="auto"
      disable-route-changes
    >
      <template #content>
        <tml-image
          class="mb-2"
          :src="upgradeToProImage"
          alt="pro popup image"
        />
        <p v-if="showFreeTrial" class="my-2">
          Try Teemill Pro free for 7 days, once your trial ends it's just £{{ upgrade.pricing.monthly }} per month. You can cancel at any time.
        </p>
        <p v-else class="my-2">
          Subscribe to Teemill Pro for only £{{ upgrade.pricing.monthly }} per month. You can cancel at any time.
        </p>
        <p>
          As soon as you have upgraded you can switch to a custom domain and
          start using your new features today.
        </p>

        <pro-billing-cycle-select
          class="mt-3"
          :billing-cycle="billingCycle"
          @toggle-billing-cycle="upgrade.setBillingCycle($event)" 
        />

        <pro-payment-form 
          class="mt-3"
          context="subscribe"
          :origin="upgrade.information.origin" 
          :billing-cycle="billingCycle"
          :with-trial="showFreeTrial"
        />
      </template>
    </tml-new-popup>
    <tml-new-popup
      v-else
      :name="name"
      title="You're already a Pro user 🎉"
      layout="standard"
      size="normal"
      height="auto"
      disable-route-changes
    >
      <template #content>
        <p class="my-2">
          Need some help? Check out our
          <tml-anchor text="Support Hub" href="https://teemill.com/support/" />
          for more.
        </p>
      </template>
    </tml-new-popup>
  </div>
</template>

