import {handleFailedChunk} from '@teemill/common/helpers';

import {isLoggedOut} from '../../guards';

export default [
  {
    path: '/reset-password/',
    name: 'reset-password',
    meta: {
      title: 'Reset Password',
      access: [isLoggedOut],
      layout: 'blank',
    },
    component: () =>
      import(
        /* webpackChunkName: "PageResetPassword" */ './ResetPassword.vue'
      ).catch(handleFailedChunk),
  },
];
