import {handleFailedChunk} from '@teemill/common/helpers';
import {hasIntegration} from '../../guards';

export default [
  {
    path: '/factory',
    name: 'factory.index',
    meta: {
      title: 'Factory',
      search: {
        show: false,
      },
      integration: 'fulfillments',
      access: [hasIntegration],
    },
    component: () => import('./FactoryIndex.vue').catch(handleFailedChunk),
    children: [
      {
        path: '',
        name: 'factory',
        meta: {
          title: 'Factory',
          breadcrumb: {
            type: 'page',
          },
          layout: 'standard',
          search: {
            show: false,
          },
        },
        component: () =>
          import('./FactoryCorePage.vue').catch(handleFailedChunk),
      },
      {
        path: 'orders',
        name: 'factory.fulfillments',
        meta: {
          title: 'Orders',
          breadcrumb: {
            type: 'page',
            parent: 'factory',
            position: 'custom',
          },
          layout: 'standard',
          search: {
            show: false,
          },
        },
        component: () =>
          import('./FulfillmentsPage.vue').catch(handleFailedChunk),
      },
      {
        path: 'orders/:id/',
        name: 'factory.fulfillments.view',
        meta: {
          title: 'View Fulfillment',
          layout: 'standard',
          requireProject: true,
          breadcrumb: {parent: 'factory.fulfillments'},

          integration: 'fulfillments',
          access: [hasIntegration],
        },
        component: () =>
          import('./fulfillments/FulfillmentView.vue').catch(handleFailedChunk),
      },
      {
        path: 'orders/:id/jobsheet',
        name: 'factory.fulfillments.jobsheet',
        meta: {
          title: 'Jobsheet',
          layout: 'blank',
          requireProject: true,
          breadcrumb: {parent: 'factory.fulfillments.view'},
          integration: 'fulfillments',
          access: [hasIntegration],
        },
        component: () =>
          import('./fulfillments/components/FulfillmentJobSheet.vue').catch(
            handleFailedChunk
          ),
      },
      {
        path: 'shipments',
        name: 'factory.shipments',
        meta: {
          title: 'Shipments',
          breadcrumb: {
            type: 'page',
            parent: 'factory',
          },
          layout: 'standard',
          search: {
            show: false,
          },
        },
        component: () => import('./ShipmentsPage.vue').catch(handleFailedChunk),
      },
      {
        path: 'shipments/create/',
        name: 'factory.shipments.create',
        meta: {
          title: 'Create Shipment',
          requireProject: true,
          breadcrumb: {parent: 'factory.shipments'},
          layout: 'standard',

          integration: ['shipments', 'shipping'],
          access: [hasIntegration],
        },
        component: () =>
          import(
            /* webpackChunkName: "PageShipmentCreate" */ './shipments/ShipmentCreate.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'shipments/:invoiceOrderId/',
        name: 'factory.shipments.view',
        meta: {
          title: 'View Shipment',
          requireProject: true,
          breadcrumb: {parent: 'factory.shipments'},
          layout: 'standard',

          integration: ['shipments', 'shipping'],
          access: [hasIntegration],
        },
        component: () =>
          import(
            /* webpackChunkName: "PageShipmentView" */ './shipments/ShipmentView.vue'
          ).catch(handleFailedChunk),
      },
      {
        path: 'ops/',
        name: 'factory.ops',
        meta: {
          title: 'Ops',
          access: [hasIntegration],
          layout: 'standard',
          integration: 'warehouse',
          allowPwaReload: true,
          breadcrumb: {
            type: 'page',
            parent: 'factory',
          },
        },
        component: () =>
          import(/* webpackChunkName: "PageOps" */ './ops/OpsIndex.vue').catch(
            handleFailedChunk
          ),
        children: [
          {
            path: 'buying/',
            name: 'factory.ops.buying.index',
            component: () =>
              import('./ops/buying/BuyingIndex.vue').catch(handleFailedChunk),
            meta: {
              title: 'Buying',
              access: [hasIntegration],
              layout: 'standard',
              integration: 'warehouse',
              allowPwaReload: true,
              breadcrumb: {
                type: 'page',
                parent: 'factory.ops',
              },
            },
            children: [
              {
                path: 'quick-buy/',
                name: 'factory.ops.buying.quick-buy',
                meta: {
                  title: 'Quick Buy',
                  access: [hasIntegration],
                  layout: 'standard',
                  integration: 'warehouse',
                  allowPwaReload: true,
                  breadcrumb: {
                    type: 'page',
                    parent: 'factory.ops.buying.index',
                  },
                },
                component: () =>
                  import(
                    /* webpackChunkName: "PageStock" */ './ops/buying/QuickBuy.vue'
                  ).catch(handleFailedChunk),
              },
            ],
          },
        ],
      },
    ],
  },
];
