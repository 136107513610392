<script setup lang="ts">
import {computed} from 'vue';
import {useProjectStore} from '../stores/project';
import {useRoute} from 'vue-router';
import {viewportSize} from '@teemill/common/helpers';

const route = useRoute();

const projectStore = useProjectStore();

const activeProject = computed(() => projectStore.active);

const parentLogo = computed(() => {
  const layoutName = route.meta.layout || 'standard';

  if (layoutName === 'one-pager') {
    return undefined;
  }

  if (activeProject.value?.platform) {
    return activeProject.value.platform.logos.find(
      logo =>
        logo.meta?.theme === 'light' &&
        logo.meta.device ===
          (viewportSize.isSmaller('md') ? 'mobile' : 'desktop')
    )?.src;
  }

  return activeProject.value?.manager?.logo;
});
</script>

<template>
  <tml-anchor
    href="/"
    class="flex items-center h-full w-full"
    aria-label="Home"
  >
    <tml-logo
      :pro="!!activeProject?.isPro"
      :flow="parentLogo ? undefined : route.path.startsWith('/factory')"
      :image="parentLogo"
      height="100%"
      teemill
      header-align
    />
  </tml-anchor>
</template>
