export default {
  namespaced: true,

  state: {
    url: '',
  },

  mutations: {
    /**
     * @name Update Logo
     * @description Updates the logo
     *
     * @param {object} state
     * @param {string} logo
     */
    update(state, logo) {
      state.url = logo;
    },
  },
};
