import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess, hasStore} from '@teemill/common/guards';
import {RouteLocationNormalized} from 'vue-router';

export default {
  path: 'audience',
  meta: {
    title: 'Audience',
    access: [hasDashboardAccess, hasStore],
    layout: 'standard',
  },
  component: () =>
    import(
      /* webpackChunkName: "PageTeemail" */ '../components/routes/audience/Index.vue'
    ).catch(handleFailedChunk),
  children: [
    {
      name: 'teemail.audience.dashboard',
      path: '',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'teemail.index',
        },
        title: 'Audience',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
        cloudRedirect: () => ({
          name: 'website.teemail.audience.index',
        }),
      },
      component: () =>
        import(
          /* webpackChunkName: "PageTeemail" */ '../components/routes/audience/Dashboard.vue'
        ).catch(handleFailedChunk),
    },
    {
      name: 'teemail.audience.mailing-lists.show',
      path: 'mailing-lists/:mailingList',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'teemail.audience.dashboard',
        },
        title: 'List',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
        cloudRedirect: (to: RouteLocationNormalized) => ({
          name: 'website.teemail.audience.show',
          params: {audience: to.params.mailingList},
        }),
      },
      component: () =>
        import(
          /* webpackChunkName: "PageTeemail" */ '../components/routes/audience/mailing-lists/Show.vue'
        ).catch(handleFailedChunk),
    },
  ],
};
