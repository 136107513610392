import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess, hasStore} from '@teemill/common/guards';
import {
  Index,
  CampaignList,
  Reschedule,
  ShowIndex,
} from '@teemill/modules/teemail/components/routes';

export default {
  path: 'campaigns',
  meta: {
    title: 'Campaigns',
    access: [hasDashboardAccess, hasStore],
    layout: 'standard',
  },
  component: Index,
  children: [
    {
      name: 'teemail.campaigns.list',
      path: '',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'teemail.index',
        },
        title: 'Campaigns',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
        cloudRedirect: () => ({
          name: 'website.teemail.campaigns.index',
        }),
      },
      component: CampaignList,
    },
    {
      path: ':campaign',
      meta: {
        breadcrumb: {
          type: 'page',
          parent: 'teemail.campaigns.list',
        },
        title: 'Campaign',
        access: [hasDashboardAccess, hasStore],
        layout: 'standard',
        cloudRedirect: to => ({
          name: 'website.teemail.campaigns.show',
          params: {
            campaign: to.params.campaign,
          },
        }),
      },
      component: ShowIndex,
      children: [
        {
          name: 'teemail.campaigns.show.overview',
          path: 'overview',
          alias: '',
          meta: {
            breadcrumb: {
              type: 'page',
              parent: 'teemail.campaigns.list',
            },
            title: 'Campaign',
            access: [hasDashboardAccess, hasStore],
            layout: 'standard',
            cloudRedirect: to => ({
              name: 'website.teemail.campaigns.show.overview',
              params: {
                campaign: to.params.campaign,
              },
            }),
          },
          component: () =>
            import('./TeemailCampaignOverviewContainer.vue').catch(
              handleFailedChunk
            ),
        },
        {
          path: 'reschedule',
          name: 'teemail.campaigns.show.reschedule',
          meta: {
            breadcrumb: {
              type: 'page',
              parent: 'teemail.campaigns.list',
            },
            title: 'Campaign',
            access: [hasDashboardAccess, hasStore],
            layout: 'standard',
            cloudRedirect: to => ({
              name: 'website.teemail.campaigns.show.reschedule',
              params: {
                campaign: to.params.campaign,
              },
            }),
          },
          component: Reschedule,
        },
        {
          path: 'preview',
          name: 'teemail.campaigns.show.preview',
          meta: {
            breadcrumb: {
              type: 'page',
              parent: 'teemail.campaigns.list',
            },
            title: 'Campaign',
            access: [hasDashboardAccess, hasStore],
            layout: 'standard',
            cloudRedirect: to => ({
              name: 'website.teemail.campaigns.show.preview',
              params: {
                campaign: to.params.campaign,
              },
            }),
          },
          component: () =>
            import('./PreviewContainer.vue').catch(handleFailedChunk),
        },
      ],
    },
  ],
};
