<script setup lang="ts">
import {computed} from 'vue';
import {faCircle} from '@fortawesome/pro-regular-svg-icons';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {viewportSize} from '@teemill/common/helpers';
import {BLUE} from '@teemill/common/assets/color/brandColours';
import {BillingCycle} from '../../types';

withDefaults(
  defineProps<{
    billingCycle: BillingCycle;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emit = defineEmits<{
  (e: 'toggle-billing-cycle', newBillingCycle: BillingCycle): void;
}>();

const billingCycleOptions = computed(() => {
  return [
    {
      value: 'yearly',
      billingCycle: 'Yearly',
      savingsText: 'Save 17.5%',
      pricingText: '£99/year (£8.25/month)',
      billingCycleText: 'Billed yearly',
    },
    {
      value: 'monthly',
      billingCycle: 'Monthly',
      savingsText: undefined,
      pricingText: '£10/month',
      billingCycleText: 'Billed monthly',
    },
  ];
});
</script>

<template>
  <tml-form form-name="pro-payment-form">
    <tml-new-segmented-control
      class="!m-0"
      identifier="value"
      name="billingCycle"
      :segment-width="viewportSize.isSmaller('md') ? 'full' : 'half'"
      :value="{value: billingCycle}"
      :options="billingCycleOptions"
      :vertical="viewportSize.isSmaller('md')"
      :disabled="disabled"
      item-padding="0"
      @new-input="emit('toggle-billing-cycle', $event.value)"
    >
      <template #default="{option}">
        <div class="relative">
          <div class="p-3">
            <h4 class="mb-0">
              {{ option.billingCycle }}
            </h4>

            <p class="text-base mb-1">
              {{ option.pricingText }}
            </p>

            <p class="text-sm mb-1 opacity-80">
              {{ option.billingCycleText }}
            </p>

            <font-awesome-icon
              class="mt-1"
              :style="{
                color: BLUE
              }"
              :icon="option.value === billingCycle ? faCheckCircle: faCircle"
            />
          </div>
          <div v-if="option.savingsText" class="absolute top-2.5 right-2 pointer-events-none">
            <slot name="savings-badge" :text="option.savingsText">
              <tml-button
                :text="option.savingsText"
                class="!inline !p-1 text-xs" 
                primary
                small
                force-pro
              />
            </slot>
          </div>
        </div>
      </template>
    </tml-new-segmented-control>
  </tml-form>
</template>