import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/masterplan/',
    name: 'masterplan',
    meta: {
      title: 'Masterplan',
      breadcrumb: {
        type: 'page',
        parent: 'dashboard',
      },
      access: hasDashboardAccess,
      layout: 'tool',
    },
    component: () =>
      import(
        /* webpackChunkName: "PageMasterplan" */ './MasterplanPage.vue'
      ).catch(handleFailedChunk),
  },
];
