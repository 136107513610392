import {ref, reactive, computed, type Ref} from 'vue';
import {assign} from 'lodash';
import {captureException} from '@sentry/vue';
import {events} from '@teemill/common/services';
import {UpgradeProps, BillingCycle, Subscription} from './types';
import {SubscriptionProvider} from './providers/subscriptionProvider';

const upgradeInformation = reactive<UpgradeProps>({
  plan: 'pro',
  origin: 'unknown',
  billingCycle: 'yearly',
});

const canReceiveFreeTrial = ref<boolean>();

export interface UseSaas {
  upgrade: {
    canReceiveFreeTrial: Ref<boolean | undefined>;
    checkIfCanReceiveFreeTrial: () => Promise<void>;
    openPopup: (props?: UpgradeProps) => void;
    information: UpgradeProps;
    pricing: {
      yearly: number;
      monthly: number;
    };
    setBillingCycle: (billingCycle: BillingCycle) => void;
  };
  subscribe: {
    openPopup: (props?: UpgradeProps) => void;
  };
  update: {
    openPopup: () => void;
  };
  subscription: {
    changePlan: (props: {
      subscriptionId: number;
      billingCycle: BillingCycle;
    }) => Promise<void>;
    getSubscription: () => Promise<Subscription>;
  };
}

export const useSaas = (): UseSaas => {
  return {
    upgrade: {
      canReceiveFreeTrial,
      checkIfCanReceiveFreeTrial,
      openPopup: openUpgradePopup,
      information: upgradeInformation,
      pricing: reactive({
        yearly: yearlyPrice,
        monthly: monthlyPrice,
      }),
      setBillingCycle,
    },
    subscribe: {
      openPopup: openSubscribePopup,
    },
    update: {
      openPopup: openUpdatePopup,
    },
    subscription: {
      changePlan,
      getSubscription,
    },
  };
};

const changePlan = async ({
  subscriptionId,
  billingCycle,
}: {
  subscriptionId: number;
  billingCycle: BillingCycle;
}): Promise<void> => {
  try {
    await SubscriptionProvider.updateBillingCycle(subscriptionId, billingCycle);
  } catch (error) {
    captureException(error);

    throw error;
  }
};

const getSubscription = async (): Promise<Subscription> => {
  try {
    return await SubscriptionProvider.getSubscription();
  } catch (error) {
    captureException(error);

    throw error;
  }
};

const yearlyPrice = computed(() =>
  upgradeInformation.billingCycle === 'yearly' ? 99 : 120
);

const monthlyPrice = computed(() => {
  const monthlyPrice = yearlyPrice.value / 12;

  if (monthlyPrice % 1 !== 0) {
    return parseFloat(monthlyPrice.toFixed(2));
  }

  return monthlyPrice;
});

const setBillingCycle = (billingCycle: BillingCycle) => {
  upgradeInformation.billingCycle = billingCycle;
};

export const openUpgradePopup = async (props?: UpgradeProps) => {
  if (props) {
    setUpgradeInformation(props);
  }

  await checkIfCanReceiveFreeTrial();

  events.emit('open-upgrade-to-pro-popup', {
    title: props?.title,
    description: props?.description,
    image: props?.image,
  });
};

export const openSubscribePopup = async (props?: UpgradeProps) => {
  if (props) {
    setUpgradeInformation(props);
  }

  await checkIfCanReceiveFreeTrial();

  events.emit('open-pro-subscribe-popup');
};

export const openUpdatePopup = () => {
  events.emit('open-pro-update-popup');
};

const setUpgradeInformation = (props: UpgradeProps) => {
  const defaultUpgradeInformation: UpgradeProps = {
    plan: 'pro',
    origin: 'unknown',
    billingCycle: 'yearly',
  };

  assign(upgradeInformation, defaultUpgradeInformation, props);
};

const checkIfCanReceiveFreeTrial = async () => {
  if (canReceiveFreeTrial.value !== undefined) {
    return;
  }

  try {
    canReceiveFreeTrial.value =
      await SubscriptionProvider.canReceiveFreeTrial();
  } catch (error) {
    captureException(error);
  }
};
