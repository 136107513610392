import {handleFailedChunk} from '@teemill/common/helpers';
import {hasDashboardAccess} from '../../guards';

export default [
  {
    path: '/upgrade-error/',
    name: 'upgrade-error',
    meta: {
      title: 'Upgrade error',
      access: hasDashboardAccess,
      layout: 'standard',
      search: {
        show: false,
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "PageUpgradeError" */ './UpgradeError.vue'
      ).catch(handleFailedChunk),
  },
];
