import {handleFailedChunk} from '@teemill/common/helpers';
import {RouteRecordRaw} from 'vue-router';
import {hasIntegration, hasDashboardPermissions} from '../../guards';

export default [
  {
    path: '/footer-editor/',
    name: 'footer-editor',
    meta: {
      title: 'Footer',
      breadcrumb: {
        type: 'page',
        parent: 'website',
      },
      integration: 'website',
      dashboardPermissions: ['edit-webpage'],
      access: [hasIntegration, hasDashboardPermissions],
      layout: 'standard',
      search: {
        icon: 'bars',
      },
    },
    component: () => import('./FooterEditor.vue').catch(handleFailedChunk),
  },
] as RouteRecordRaw[];
